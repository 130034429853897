import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import './Navigation.css'
import { useCart } from '../../Providers/CartProvider';
import { useAuth } from '../../auth/authContext';

const Navigation = () => {
  const { cart } = useCart();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { user, isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();


  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <header className={`navBar ${isScrolled ? 'scroll' : ''}`}>
        <nav>
          <ul>
            <li>
              <NavLink to="/" activeClassName="activeLink" exact>صفحه اصلی</NavLink>
            </li>
            <li>
              <NavLink to="/about-us" activeClassName="activeLink" exact>درباره ما</NavLink>
            </li>
            <li>
              <NavLink to="/contact-us" activeClassName="activeLink" exact>ارتباط باما</NavLink>
            </li>
            <li>
              <a href='https://www.instagram.com/aroosi.online/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==' target='_blank'>پیج اینستاگرام</a>
            </li>
            {/* <li>
            <NavLink to="/blog" activeClassName="activeLink">وبلاگ</NavLink>
          </li>
          <li>
            <NavLink to="/card" activeClassName="activeLink">کارت عروسی</NavLink>
          </li>
          <li>
            <NavLink to="/dashboard" activeClassName="activeLink">داشبورد</NavLink>
          </li>
          <li>
            <NavLink to="/card3" activeClassName="activeLink">card3</NavLink>
          </li>
          <li>
            <NavLink to="/cart" activeClassName="activeLink">سبد
              <span style={{ backgroundColor: cart.length === 1 ? "green" : "red" }} className='activeBasket'></span>
            </NavLink>
          </li> */}
          </ul>
        </nav>
        <div className='logo'>
          <NavLink to="/">
            Aroosi.online
          </NavLink>
        </div>
        <div className='leftSide'>
          {isAuthenticated ?
            <div>
              {/* <span>{user.name}</span> */}
              <button type='button' className='logOut' onClick={handleLogout}>
                خروج
              </button>
              <NavLink to="/dashboard" activeClassName="activeLink">
                <img alt="" className="accountIcon" src="/asset/media/pic/icon/bride-account.png" />
              </NavLink>
            </div>
            :
            <div className='loginButton'>
              <NavLink to="/login" activeClassName="activeLink">ورود</NavLink>
            </div>
          }
        </div>
        <button className='hambergerBtn' onClick={toggleSidebar}>☰</button>
      </header>



      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <nav>
          <ul>
            <li>
              <NavLink to="/" activeClassName="activeLink" exact>صفحه اصلی</NavLink>
            </li>
            <li>
              <NavLink to="/about-us" activeClassName="activeLink">درباره ما</NavLink>
            </li>
            {/* <li>
              <NavLink to="/blog" activeClassName="activeLink">وبلاگ</NavLink>
            </li> */}

            {
              isAuthenticated ?
                (
                  <>
                    <li>
                      <NavLink to="/dashboard" activeClassName="activeLink">
                        پنل کاربری
                      </NavLink>
                    </li>

                    <li>
                      <button onClick={handleLogout}>خروج</button>
                    </li>
                  </>
                ) :
                (
                  <div className='loginButton'>
                    <NavLink to="/login" activeClassName="activeLink">ورود</NavLink>
                  </div>
                )
            }

          </ul>
        </nav>
        <button className='close' onClick={toggleSidebar}>
          <img alt="" className="" src="/asset/media/pic/icon/close.png" />
        </button>
      </div>

      {isSidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}
    </div>
  );
};

export default Navigation;

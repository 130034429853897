import Layout from "../Layout/Layout";
import PlansComponent from "../components/main/plans";
import OptionsComponent from "../components/main/options";
import * as data from "../data.js";
import BannerComponent from "../components/main/banner";
import AboutUsComponent from "../components/main/aboutUs";
import WhyUsComponent from "../components/main/whyUs";
import TreeComponent from "../components/main/tree";

const HomePage = () => {
    return (
        <Layout>
            <BannerComponent />
            <AboutUsComponent />
            <WhyUsComponent />
            <PlansComponent />
            {/* <TreeComponent /> */}
            {/* <main className="">
                <section className="blogList">
                    {data?.blogs?.map((blog) => {
                        return (
                            <div className="blog" key={blog.id}>
                                {blog.name}\
                                {blog.desp}
                            </div>
                        );
                    })}
                </section>
                <OptionsComponent /> 
            </main> */}
        </Layout>
    );
};

export default HomePage;

import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';

const UploadImage = ({ onImageUpload, initialImageUrl }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(initialImageUrl);

    useEffect(() => {
        if (initialImageUrl) {
            setImagePreviewUrl(initialImageUrl);
        }
    }, [initialImageUrl]);

    const dropzoneStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px dashed #6c5ce7',
        backgroundColor: '#a29bfe13',
        borderRadius: '4px',
        padding: '20px',
        cursor: 'pointer',
        transition: 'border-color 0.3s ease-in-out',
        maxWidth: "200px",
        width: "100%"
    };

    const hoverStyle = {
        borderColor: '#aaa',
    };

    const imgStyle = {
        maxWidth: '100%',
        maxHeight: '200px',
    };
    const removeButton = {
        background: 'none',
        border: 'none',
        color: '#ff0000',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginTop: "5px"
    };

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setSelectedImage(file);
        setImagePreviewUrl(URL.createObjectURL(file));

        if (onImageUpload) {
            onImageUpload(file);
        }
    };

    const removeImage = () => {
        setSelectedImage(null);
        setImagePreviewUrl(null);
        // You can also call a function to handle image removal on the parent component
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'image/*',
        onDrop,
    });

    return (
        <>
            <Box
                style={{ ...dropzoneStyle, ...(isDragActive && hoverStyle) }}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                {imagePreviewUrl ? (
                    <div>
                        <img src={imagePreviewUrl} alt="Uploaded" style={imgStyle} />
                    </div>
                ) : (
                    <AddIcon fontSize="large" style={{ color: '#6c5ce7' }} />
                )}
            </Box>
            {imagePreviewUrl && (
                <button style={removeButton} onClick={removeImage}>
                    <DeleteIcon fontSize="small" style={{ color: '#6c5ce7' }} />
                </button>
            )}
        </>
    );
};

export default UploadImage;

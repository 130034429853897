import { useEffect, useState } from "react";
import "./CardPage4.css";
import Section1Page from "./components/Section1";
import Section2Page from "./components/Section2";
import Section3Page from "./components/Section3";
import Section4Page from "./components/section4";
import Section5Page from "./components/Section5";
import { useAuth } from "../../../auth/authContext";
import Section7Page from "./components/Section7";
import { useLocation } from 'react-router-dom';
import Section6Page from "./components/Section6";


const CardPage4 = ({ guestCart }) => {
  const location = useLocation();
  const exampleDataCart = location?.state?.guestCart;
  const [cart, setCart] = useState("cardPage4");
  const { cartData } = useAuth();

  useEffect(() => {
    setCart("cardPage4");
  }, [])
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={`cardPage ${cart}`}>
      <nav className="sidebarCart">
        <ul>
          <li onClick={() => scrollToSection('section4')}>زمان</li>
          <li onClick={() => scrollToSection('section1')}>کارت</li>
          <li onClick={() => scrollToSection('section2')}>لوکیشن</li>
          {
            cartData?.show_memento || guestCart?.wedding?.show_memento || exampleDataCart?.wedding?.show_memento ?
              <li onClick={() => scrollToSection('section3')}>یادبودها</li>
              :
              <></>
          }
          {/* <li onClick={() => scrollToSection('section5')}>گالری</li> */}
          {
            cartData?.show_survey || guestCart?.wedding?.show_survey || exampleDataCart?.wedding?.show_survey ?
              <li onClick={() => scrollToSection('section7')}>نظرسنجی</li>
              :
              <></>
          }
        </ul>
      </nav>
      <nav className="sidebarCardMobile">
        <ul>
          <li onClick={() => scrollToSection('section4')}>زمان</li>
          <li onClick={() => scrollToSection('section1')}>کارت</li>
          <li onClick={() => scrollToSection('section2')}>لوکیشن</li>
          {
            cartData?.show_memento || guestCart?.wedding?.show_memento || exampleDataCart?.wedding?.show_memento ?
              <li onClick={() => scrollToSection('section3')}>یادبودها</li>
              :
              <></>
          }
          {/* <li onClick={() => scrollToSection('section5')}>گالری</li> */}
          {
            cartData?.show_survey || guestCart?.wedding?.show_survey || exampleDataCart?.wedding?.show_survey ?
              <li onClick={() => scrollToSection('section7')}>نظرسنجی</li>
              :
              <></>
          }
        </ul>
      </nav>
      <main>
        {guestCart?.name ?
          <div className="guestName">{guestCart?.name}</div>
          :
          <></>
        }

        <section id="section4">
          <Section4Page cartData={cartData || guestCart?.wedding || exampleDataCart?.wedding} />
        </section>
        <section id="section1">
          <Section1Page cartData={cartData || guestCart?.wedding || exampleDataCart?.wedding} />
        </section>
        <section id="section2">
          <Section2Page cartData={cartData || guestCart?.wedding || exampleDataCart?.wedding} />
        </section>
        {
          cartData?.show_memento || guestCart?.wedding || exampleDataCart?.wedding?.show_memento ?
            <section id="section3">
              <Section3Page cartData={guestCart || exampleDataCart?.wedding} />
            </section>
            :
            <></>
        }
        {
          cartData?.show_survey || guestCart?.wedding?.show_survey || exampleDataCart?.wedding?.show_survey ?
            <section id="section7">
              <Section7Page cartData={cartData || guestCart || exampleDataCart?.wedding} />
            </section>
            :
            <></>
        }
        {/* <section id="section5">
          <Section5Page cartData={cartData || guestCart?.wedding || exampleDataCart?.wedding}/>
        </section> */}
        <section id="section6">
          <Section6Page cartData={cartData || guestCart?.wedding || exampleDataCart?.wedding} />
        </section>
      </main>
    </div>
  );
};


export default CardPage4;
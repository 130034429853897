import { Box, Typography, Grid } from '@mui/material';
import Axios from 'axios';
import { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../auth/authContext';
import { Link } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const MementoPage = () => {
  const { token, cartData } = useAuth();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [comment, setComment] = useState([]);

  const toggleRowSelection = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((i) => i !== index));
      setSelectAll(false)
    } else {
      setSelectedRows([...selectedRows, index]);
      if (selectedRows?.length + 1 === comment?.length) { setSelectAll(true) }
    }
  };




  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(Array.from({ length: comment?.length }, (_, index) => index + 1));
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    Axios.get(`${BASE_URL}/api/licence/current`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        const responseData = response?.data?.wedding?.mementos;
        const updatedSelectedRows = responseData
          ?.filter(item => Number(item?.show))
          ?.map(item => item?.id) || [];
        setComment(responseData);
        setSelectedRows(updatedSelectedRows);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);


  const handleSubmit = (values) => {
    axios.post(`${BASE_URL}/api/memento/edit`, {
      mementos: selectedRows,
      show: 1,
    },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        toast.success(response?.data?.message);
      })
      .catch(error => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <Box style={{ width: "1000px", maxWidth: "100%", margin: "0 auto", position: 'relative' }}>
      {cartData?.licence_id === 1 ?
        <Box className="licenseDisable">
          <Link to="/plan/3" className="licenseLink">اپدیت پکیج</Link>
        </Box>
        : ""
      }
      <Grid container spacing={2} sx={{ width: "100%" }}>
        <Grid item xs={12} style={{ paddingLeft: 0, marginBottom: '80px' }}>
          <Typography variant='h5' sx={{ mb: 1, mt: 2, color: '#40407a' }}>یادبودها</Typography>
          <Typography variant='p' sx={{ mt: 1 }}>با فعال کردن گزینه <span className='square'></span> و ذخیره تغیرات مشخص کنید کدام یادبودها در کارت شما، برای همه مهمان‌ها نمایش داده شود.</Typography>
          {!comment ? (
            <Typography style={{
              padding: "20px 10px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              borderRadius: "4px"
            }}
            >در حال حاضر یادبودی ثبت نشده است.</Typography>
          ) : (
            <TableContainer component={Paper} style={{ marginTop: "10px" }}>
              <Table style={{ width: "1000px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="right">#</TableCell>
                    <TableCell align="right">نام</TableCell>
                    <TableCell align="right">موبایل</TableCell>
                    <TableCell align="right" style={{ width: '50%' }}>پیام</TableCell>
                    {/* <TableCell align="right">حضور</TableCell> */}
                    <TableCell align="right" style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        checked={selectAll}
                        onChange={toggleSelectAll}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {comment?.map((comment, index) => (
                    <TableRow key={index} >
                      <TableCell align="right">{comment?.id}</TableCell>
                      <TableCell align="right">{comment?.cart?.name}</TableCell>
                      <TableCell align="right">{comment?.cart?.mobile}</TableCell>
                      <TableCell align="right">
                        {comment?.name ?
                          <h6 style={{ color: "#a29bfe" }}>{comment?.name}:</h6>
                          :
                          <></>
                        }
                        <p>{comment?.text}</p>
                      </TableCell>
                      {/* <TableCell align="right">{comment?.coperation ? <CheckIcon color="success" /> : <ClearIcon color="error" />}</TableCell> */}
                      <TableCell align="left">
                        <Checkbox
                          checked={(selectAll || selectedRows.includes(comment?.id))}
                          onChange={() => toggleRowSelection(comment?.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>

      <button className="saveInformation chageBtnComment" type="submit" onClick={handleSubmit}>ذخیره تغیرات</button>
    </Box>
  );

};


export default MementoPage;
import { useState, useEffect } from 'react';
import SearchBar from 'material-ui-search-bar';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, TextField, Stack, Typography, Box, Checkbox, FormControl, InputLabel, FormHelperText, TablePagination } from '@mui/material';
import ModalComponent from '../../../components/ModalComponent.tsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import Input from '../../../common/input.js';
import { Toast, toast } from "react-toastify";
import { useAuth } from '../../../auth/authContext.js';
import CustomSelector from '../../../components/Selector/CustomSelector.jsx';
import { Link } from 'react-router-dom';
// import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const filters = [
    {
        id: 1, label: 'Option 1', text: "متن 1"
    },
    {
        id: 2, label: 'Option 2', text: "متن 2"
    },
    {
        id: 3, label: 'Option 3', text: "متن3"
    },
    {
        id: 4, label: 'Option 4', text: "متن4"
    },
];

function GuestListApp() {
    const [textCard, setTextCard] = useState("");
    const { token, cartData, fetchCartData } = useAuth();

    const [guests, setGuests] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const [isloadingAdd, setIsloadingAdd] = useState(false);
    const [isloadingEdit, setIsloadingEdit] = useState(false);
    const [isloadingSend, setIsloadingSend] = useState(false);


    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenEdit, setModalOpenEdit] = useState(false);
    const [modalSendOpen, setModalSendOpen] = useState(false);
    const [modalDetailsOpen, setModalDetailsOpen] = useState(false);
    const [indexDetails, setIndexDetails] = useState();

    const [editingIndex, setEditingIndex] = useState(-1);
    const [page, setPage] = useState(0); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(5); // Number of rows per page
    const [filteredGuests, setFilteredGuests] = useState([]);

    const [searched, setSearched] = useState("");
    const [smsText, setSmsText] = useState([]);
    const [smsTextEachGuest, setSmsTextEachGuest] = useState([]);



    const handleSelection = (selectedValue) => {
        setTextCard(selectedValue);
    };

    const handleFilteredGuest = (selectedValue) => {
        axios.get(`${BASE_URL}/api/cart?wedding_sms_id=${selectedValue}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                toast.success(response?.data?.message);
                setGuests(response?.data?.carts)
                setFilteredGuests(response?.data?.carts);
            })
            .catch(error => {
                toast.error(error?.response?.data?.message);
            });
    };

    useEffect(() => {
        setFilteredGuests(guests);
    }, [guests]);

    useEffect(() => {
        axios.get(`${BASE_URL}/api/cart`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                toast.success(response?.data?.message);
                setGuests(response?.data?.carts)
                setFilteredGuests(response?.data?.carts);
            })
            .catch(error => {
                toast.error(error?.response?.data?.message);
            });

        axios.get(`${BASE_URL}/api/sms/templates`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                toast.success(response?.data?.message);
                setSmsText(response?.data);
            })
            .catch(error => {
                toast.error(error?.response?.data?.message);
            });
    }, [])

    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
            guestCount: '',
            // email: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('نام را وارد کنید'),
            mobile: Yup.string().matches(/^\d+$/, 'شماره موبایل باید عدد باشد').required('موبایل را وارد کنید'),
            guestCount: Yup.number().integer('تعداد مهمان ها باید یک عدد صحیح باشد').positive('تعداد مهمان ها باید بیشتر از صفر باشد').required('تعداد مهمان ها را وارد کنید').max(9, "تعداد مهمان‌ها بیش از حد مجاز است"),
            // email: Yup.string().email('ایمیل معتبر نیست'),
        }),
        onSubmit: (values, { resetForm }) => {
            const newGuest = { ...values };
            if (editingIndex !== -1) {
                setIsloadingEdit(true);
                axios.put(`${BASE_URL}/api/cart`,
                    {
                        id: guests[editingIndex]?.id,
                        name: values?.name,
                        mobile: values?.mobile,
                        number_of_guests: values?.guestCount,
                    }
                    ,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                    .then(response => {
                        toast.success(response?.data?.message);
                        const updatedGuests = [...guests];
                        // updatedGuests[editingIndex] = newGuest;
                        updatedGuests[editingIndex] = {
                            ...updatedGuests[editingIndex], // Preserve other fields
                            name: values?.name,
                            mobile: values?.mobile?.toString(),
                            number_of_guests: values?.guestCount
                        };
                        setGuests(updatedGuests);
                        setEditingIndex(-1);
                        setIsloadingEdit(false);
                        toggleModalEdit();
                        fetchCartData();
                    })
                    .catch(error => {
                        toast.error(error?.response?.data?.message);
                        setIsloadingEdit(false)
                    });

            } else {
                // setGuests([...guests, newGuest]);
                setIsloadingAdd(true);
                axios.post(`${BASE_URL}/api/cart`,
                    {
                        name: values?.name,
                        mobile: values?.mobile,
                        number_of_guests: values?.guestCount,
                    }
                    ,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                    .then(response => {
                        toast.success(response?.data?.message);
                        setGuests(response?.data?.carts);
                        setIsloadingAdd(false);
                        resetForm();
                        toggleModal();
                        fetchCartData();
                    })
                    .catch(error => {
                        toast.error(error?.response?.data?.message);
                        setIsloadingAdd(false);
                    });
            }
        },
    });

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const toggleModalEdit = () => {
        setModalOpenEdit(!modalOpenEdit);
    };

    const toggleModalSend = () => {
        setModalSendOpen(!modalSendOpen);
    }

    const toggleModalDetails = (id) => {
        setModalDetailsOpen(!modalDetailsOpen);
        setIndexDetails(id);
        const smsTexts = guests.find((guest) => guest?.id == id);
        setSmsTextEachGuest(smsTexts)

    }

    // const addGuest = () => {
    //     const newGuest = { name, mobile, guestCount };
    //     if (editingIndex !== -1) {
    //         const updatedGuests = [...guests];
    //         updatedGuests[editingIndex] = newGuest;
    //         setGuests(updatedGuests);
    //         setEditingIndex(-1);
    //     } else {
    //         setGuests([...guests, newGuest]);
    //     }
    //     setName('');
    //     setMobile('');
    //     setGuestCount(1);
    //     // setEmail('');
    //     toggleModal();
    // };

    const clearModal = () => {
        formik.setValues({
            name: '',
            mobile: '',
            guestCount: '',
        });
    };

    const editGuest = (index, id, Issend) => {
        const guestToEdit = guests[index];
        formik.setValues({
            name: guestToEdit?.name,
            mobile: guestToEdit?.mobile,
            guestCount: guestToEdit?.number_of_guests,
            // email: guestToEdit.email,
        });
        setEditingIndex(index);
        toggleModalEdit();
    };

    const deleteGuest = (guest) => {
        // const updatedGuests = guests?.filter((_, i) => i !== index);
        // setGuests(updatedGuests);
        axios.post(`${BASE_URL}/api/cart/delete`,
            {
                id: guest?.id,
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                toast.success(response?.data?.message);
                setGuests(response?.data?.carts);
                fetchCartData();
            })
            .catch(error => {
                toast.error(error?.response?.data?.message);
            });

    };

    const calculateTotalGuestCount = () => {
        return guests?.reduce((total, guest) => total + Number(guest?.number_of_guests), 0);
    }

    const toggleRowSelection = (guestId) => {
        if (selectedRows.includes(guestId)) {
            setSelectedRows(selectedRows.filter((i) => i !== guestId));
            setSelectAll(false)
        } else {
            setSelectedRows([...selectedRows, guestId]);
            if (selectedRows.length + 1 === guests.length) { setSelectAll(true) }
        }
    }

    function sendSelectedGuests() {
        // const selectedGuestsData = selectedRows?.map(index => guests[index]?.id);
        setIsloadingSend(true);
        axios
            .post(`${BASE_URL}/api/sms/send`,
                {
                    cart_ids: selectedRows,
                    id: textCard,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            .then(response => {
                setSelectedRows([]);
                toast.success(response?.data?.message);
                setIsloadingSend(false);
                setModalSendOpen(false);
                fetchCartData();
            })
            .catch(error => {
                toast.error(error?.response?.data?.message);
                setIsloadingSend(false);
            });
    }

    const toggleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            // setSelectedRows(Array.from({ length: guests?.length }, (_, index) => index));
            setSelectedRows(guests?.map(guest => guest?.id) || []);
        }
        setSelectAll(!selectAll);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const applyFilters = () => {
    //     let filteredData = guests?.filter(guest => {
    //         const searchString = guest.name.toLowerCase();
    //         const nameMatch = filters?.name ? searchString.includes(filters?.name.toLowerCase()) : true;
    //         return nameMatch;
    //     });
    //     setFilteredGuests(filteredData);
    // };

    // useEffect(() => {
    //     applyFilters();
    // }, [filters]);

    const reuestSearch = (searchedVal) => {
        if (searchedVal?.length === 0) {
            reuestSearch(guests);
        }
        const filteredRows = guests.filter((guest) => {
            return guest?.name?.toString()?.toLowerCase()?.includes(searchedVal?.toString()?.toLowerCase());
        });

        setFilteredGuests(filteredRows);
    }

    // const cancleSearch = () => {
    //     setSearched("");
    //     reuestSearch(guests);
    // }

    return (
        <Box style={{ width: "1200px", maxWidth: "100%", margin: "0 auto", position: 'relative' }}>
            {cartData?.licence_id === 1 ?
                <Box className="licenseDisable">
                    <Link to="/plan/3" className="licenseLink">اپدیت پکیج</Link>
                </Box>
                : ""
            }
            <Typography variant="h3" mb={3} mt={3}>لیست مهمان ها</Typography>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    marginBottom: "20px",

                    "& > *": {
                        margin: "0 0px 8px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // backgroundColor: "#74b9ff",
                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                        p: 2,
                        borderRadius: "15px",
                        minWidth: "180px",
                        width: "24%",
                        img: { width: "50px" }
                    },
                }}
            >
                <Box>
                    <Typography variant="p" mb={1}>تعداد مهمان ها</Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <img src="/asset/media/icon/guestes.png" alt="" />
                        <Typography variant="h5">{cartData?.totalNumberOfGuests}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography variant="p" mb={1}>کارت ها</Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <img src="/asset/media/icon/cart.png" alt="" />
                        <Typography variant="h5">{cartData?.totalCarts}</Typography>
                    </Box>
                </Box>

                <Box>
                    <Typography variant="p" mb={1}>SMSهای ارسال شده</Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <img src="/asset/media/icon/send.png" alt="" />
                        <Typography variant="h5">{cartData?.totalSmsSends}</Typography>
                    </Box>
                </Box>

                <Box>
                    <Typography variant="p" mb={1}>کارت‌های ارسال نشده</Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <img src="/asset/media/icon/not-send.png" alt="" />
                        <Typography variant="h5">{cartData?.totalCartsWithoutSms}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    // alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                }}
            >
                <Button
                    sx={{
                        backgroundColor: '#474787',
                        color: '#ffffff',
                        margin: "0 0 5px 5px !important",
                        width: "150px",
                        '&:hover': {
                            backgroundColor: '#706fd3',
                        },
                    }}
                    variant="contained"
                    onClick={() => {
                        toggleModal();
                        clearModal();
                    }}
                >افزودن مهمان</Button>

                <Box style={{ width: "-webkit-fill-available" }}>
                    <CustomSelector options={smsText} placeholder={"فیلتر براساس عدم ارسال sms"} onSelection={handleFilteredGuest} labelName='text' valueName='id' style={{ width: "50px" }} />
                </Box>
            </Box>

            {/* <CustomSelector options={smsText} placeholder={"فیلتر براساس عدم ارسال sms"} onSelection={handleFilteredGuest} labelName='text' valueName='id'/> */}

            {
                selectedRows.length > 0 && (
                    <Button
                        sx={{
                            backgroundColor: '#b71540',
                            color: '#ffffff',
                            marginRight: "8px",
                            position: "fixed",
                            left: "10px",
                            bottom: "20px",
                            zIndex: "3",
                            '&:hover': {
                                backgroundColor: '#eb2f06',
                            },
                        }}
                        variant="contained" onClick={toggleModalSend}>ارسال کارت‌ها با پیامک</Button>
                )
            }

            {/* <Box>
                <SearchBar
                    value={searched}
                    onChange={(newValue) => reuestSearch(newValue)}
                    onRequestSearch={() => cancleSearch()}
                />
            </Box> */}

            <TableContainer component={Paper} style={{ marginTop: "20px" }}>
                <Table style={{ minWidth: "1000px" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">#</TableCell>
                            <TableCell align="right">
                                <div>نام</div>
                            </TableCell>
                            <TableCell align="right">
                                <div>موبایل</div>
                            </TableCell>
                            <TableCell align="right">
                                <div>تعداد مهمان ها</div>
                            </TableCell>
                            {/* <TableCell align="right">ایمیل</TableCell> */}
                            <TableCell align="right">
                                <div>کارت اختصاصی</div>
                            </TableCell>
                            <TableCell align="right">
                                <div>پیامک‌ها</div>
                            </TableCell>
                            <TableCell align="right">
                                <Checkbox
                                    checked={selectAll}
                                    onChange={toggleSelectAll}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {filteredGuests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((guest, index) => ( */}
                        {filteredGuests.map((guest, index) => (
                            <TableRow key={index}>
                                <TableCell align="right">{index + 1}</TableCell>
                                <TableCell align="right">{guest?.name}</TableCell>
                                <TableCell align="right" style={{ opacity: Number(guest?.sms_send) ? "0.4" : "1" }}>{guest?.mobile}</TableCell>
                                <TableCell align="right">{guest?.number_of_guests}</TableCell>
                                <TableCell align="center">
                                    <Link to={`/${guest?.wedding?.slug}/${guest?.code}`} target="_blank">
                                        <img src="/asset/media/icon/show-card.png" alt="" style={{ width: "35px" }} />
                                    </Link>
                                    {/* <Link to={renderLink()} target="_blank">xxxx</Link> */}
                                </TableCell>
                                {/* <TableCell align="right" style={{ color: guest.status === "send" ? "green" : "red" }}>{guest.status ? guest.status : "فرستاده نشده"}</TableCell> */}
                                <TableCell align="center">
                                    <Button onClick={() => toggleModalDetails(guest?.id)}>
                                        <img src="/asset/media/icon/sms.png" alt="" style={{ width: "35px" }} />
                                    </Button>
                                </TableCell>
                                <TableCell align="right">
                                    <Checkbox
                                        checked={selectAll || selectedRows.includes(guest?.id)}
                                        onChange={() => toggleRowSelection(guest?.id)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button sx={{ color: "#f9ca24" }} onClick={() => editGuest(index, guest?.id, guest?.sms_send)}>ادیت</Button>
                                    <Button sx={{ color: "#d63031" }} onClick={() => deleteGuest(guest)}>حذف</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredGuests?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="تعداد ردیف در هر صفحه:"
                    // labelDisplayedRows={({ from, to, count }) => `${count} از ${from}-${to}`}
                    labelDisplayedRows={({ from, to, count }) => ``}
                />
            </TableContainer>

            <ModalComponent open={modalOpen} setOpen={toggleModal} title='مهمان جدید'>
                <div className="">
                    <div className="">
                        <Stack spacing={2}>

                            <Input formik={formik} name="name" id="name" label="نام" type="text" placeholder="جناب آقای فرامرز احمدی و بانو سارا حمیدی"></Input>
                            <Input formik={formik} name="mobile" id="mobile" label="موبایل" type="number" placeholder="09121284567" max={11}></Input>
                            <Input formik={formik} name="guestCount" id="guestCount" label="تعداد مهمان‌ها" type="number" placeholder="2"></Input>



                            {/* <FormControl fullWidth>
                                <InputLabel htmlFor="name">نام</InputLabel>
                                <Input
                                    id="name"
                                    {...formik.getFieldProps('name')}
                                />
                                <FormHelperText error>{formik.touched.name && formik.errors.name}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="mobile">موبایل</InputLabel>
                                <Input
                                    id="mobile"
                                    type="number"
                                    {...formik.getFieldProps('mobile')}
                                />
                                <FormHelperText error>{formik.touched.mobile && formik.errors.mobile}</FormHelperText>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel htmlFor="guestCount">تعداد مهمان ها</InputLabel>
                                <Input
                                    id="guestCount"
                                    type="number"
                                    {...formik.getFieldProps('guestCount')}
                                />
                                <FormHelperText error>{formik.touched.guestCount && formik.errors.guestCount}</FormHelperText>
                            </FormControl> */}



                            {/* <Input
                                label="تعداد مهمان ها"
                                type="number"
                                {...formik.getFieldProps('guestCount')}
                                error={formik.touched.guestCount && Boolean(formik.errors.guestCount)}
                                helperText={formik.touched.guestCount && formik.errors.guestCount}
                            /> */}
                            {/* <TextField
                                label="ایمیل(اختیاری)"
                                {...formik.getFieldProps('email')}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            /> */}
                            <Button
                                sx={{
                                    backgroundColor: '#474787',
                                    color: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#706fd3',
                                    },
                                }}
                                variant="contained" onClick={formik.handleSubmit} disabled={isloadingAdd}>افزودن</Button>
                        </Stack>
                    </div>
                </div>
            </ModalComponent>

            <ModalComponent open={modalOpenEdit} setOpen={setModalOpenEdit} title='ادیت مهمان'>
                <div className="">
                    <div className="">
                        <Stack spacing={2}>

                            <Input formik={formik} name="name" id="name" label="نام" type="text" placeholder="جناب آقای فرامرز احمدی و بانو سارا حمیدی"></Input>
                            <Input formik={formik} name="mobile" id="mobile" label="موبایل" type="number" placeholder="09121284567" max={11} disabled={guests[editingIndex]?.sms_send}></Input>
                            <Input formik={formik} name="guestCount" id="guestCount" label="تعداد مهمان‌ها" type="number" placeholder="2"></Input>

                            <Button
                                sx={{
                                    backgroundColor: '#474787',
                                    color: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#706fd3',
                                    },
                                }}
                                variant="contained" onClick={formik.handleSubmit} disabled={isloadingEdit}>ادیت</Button>
                        </Stack>
                    </div>
                </div>
            </ModalComponent>

            <ModalComponent open={modalSendOpen} setOpen={toggleModalSend} divider={false}>
                <Box style={{ overflow: "visible", }}>
                    <Typography sx={{ marginBottom: "8px !important" }}>متن پیام کوتاه را انتخاب کنید.</Typography>

                    <CustomSelector options={smsText} placeholder={"انتخاب کنید"} onSelection={handleSelection} labelName='text' valueName='id' />

                    <Box mt={5} sx={{ textAlign: "left" }}>
                        <Button
                            sx={{
                                backgroundColor: '#b71540',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#eb2f06',
                                },
                            }}
                            variant="contained" onClick={toggleModalSend}>بستن</Button>
                        <Button
                            sx={{
                                backgroundColor: '#38ada9',
                                color: '#ffffff',
                                marginRight: "8px",
                                '&:hover': {
                                    backgroundColor: '#60a3bc',
                                },
                            }}
                            variant="contained" onClick={sendSelectedGuests} disabled={isloadingSend || textCard === ""}>ارسال کارت‌ها</Button>
                    </Box>
                </Box>
            </ModalComponent>

            <ModalComponent open={modalDetailsOpen} setOpen={toggleModalDetails} divider={false}>
                <Box>
                    <Typography sx={{ marginBottom: "20px !important" }}>پیام‌های ارسال شده به
                        <span style={{ color: "#6c5ce7" }}> {smsTextEachGuest?.name} :</span>
                    </Typography>
                    <Box>
                        {/* {guests?.filter((_, i) => i === indexDetails)[0]?.name} */}
                        {smsTextEachGuest?.sms_responses && smsTextEachGuest?.sms_responses.length > 0 ? (
                            smsTextEachGuest?.sms_responses.map((sms, index) => {
                                const parsedContent = JSON.parse(sms?.content);
                                const message = parsedContent.return.message;
                                return (
                                    <Typography
                                        variant="body1"
                                        key={index}
                                        style={{ borderBottom: "1px solid #a29bfe", padding: "8px 0", whiteSpace: "pre-wrap" }}
                                    >
                                        <span style={{ color: "#6c5ce7" }}> {index + 1}. </span> {message}
                                    </Typography>
                                );
                            })
                        ) : (
                            <Typography variant="body1" style={{ color: "#6c5ce7" }}>
                                پیامی فرستاده نشده است.
                            </Typography>
                        )}


                    </Box>
                </Box>
            </ModalComponent>
        </Box >
    );
}

export default GuestListApp;

import "../../DashboardPage.css";
import NamesPage from "./components/Names";
import TextPage from "./components/Text";
import DatePage from "./components/Date";
import TalarPage from "./components/Talar";
import ImagePage from "./components/Image";
import ChoosePage from "./components/Choose";
import GalleryPage from "./components/Gallery";
import ChooseCardPage from "./components/chooseCard";
import { Link } from "react-router-dom";

const Section3Page = ({ cartData }) => {

  return (
    <>
      <NamesPage />
      <TextPage />
      <DatePage />
      <TalarPage />
      <ChoosePage />
      <ChooseCardPage />
      <ImagePage />
      <GalleryPage />

      <div className="cartPageOption">
        <div className="support">
          <a href="https://t.me/aroosiOnline" target="_blank">
            <span>پشتیبانی</span>
            <img src='/asset/media/pic/icon/support.png' />
          </a>
        </div>

        {/* <div className="showCard">
          <Link to="/card">
          <img src='/asset/media/pic/icon/invitation-card.png' />
            <span>نمایش</span>
          </Link>
        </div> */}
      </div>
    </>
  );
};


export default Section3Page;
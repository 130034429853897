import { useState } from 'react';
import "../Dashboard/DashboardPage.css";
import Section2Page from "./components/Section2";
import Section3Page from "./components/Section3/Section3";
import Section5Page from "./components/Section5";
import { Link } from 'react-router-dom';
import { useAuth } from '../../auth/authContext';
import Section1Page from './components/TopDashboard';
import MementoPage from "./components/Memento";
import SurveriesPage from "./components/Surveries";


const DashboardPage = () => {
  const [activeSection, setActiveSection] = useState('section1');
  const { cartData } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId);
  };
  const handleSectionMobileClick = (sectionId) => {
    setActiveSection(sectionId);
    toggleSidebar();
  };

  const renderLink = () => {
    switch (Number(cartData?.template_id)) {
      case 1:
        return "/card";
      case 2:
        return "/card2";
      case 3:
        return "/card3";
      case 4:
        return "/card4";
      case 5:
        return "/card5";
      case 6:
        return "/card6";
      case 7:
        return "/card7";
      case 8:
        return "/card8";
      default:
        return "/card";
    }
  };

  return (
    <div className="dashboardPage">
      <button className='hambergerBtn' onClick={toggleSidebar}>☰</button>

      <nav className="sidebarDashboard">
        <ul>
          <li onClick={() => handleSectionClick('section1')}>داشبورد</li>
          {/* <li onClick={() => handleSectionClick('section2')}>ویرایش مشخصات کاربر</li> */}
          <li onClick={() => handleSectionClick('section3')}>مدیریت کارت عروسی</li>
          <li onClick={() => handleSectionClick('section5')}>لیست مهمان ها</li>
          <li onClick={() => handleSectionClick('MementoPage')}>یادبودها</li>
          <li onClick={() => handleSectionClick('SurveriesPage')}>نظرسنجی‌ها</li>
          {cartData?.template_id ?
            <li className='showCard'>
              <Link to={renderLink()} target="_blank">نمایش کارت
                <img src='/asset/media/pic/icon/invitation-card.png' />
              </Link>
            </li>
            :
            <></>
          }
        </ul>
      </nav>

      {isSidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}
      <nav className={`sidebarDashboardMobile ${isSidebarOpen ? 'open' : ''}`}>
        <ul>
          <li onClick={() => handleSectionMobileClick('section1')}>داشبورد</li>
          {/* <li onClick={() => handleSectionMobileClick('section2')}>کاربر</li> */}
          <li onClick={() => handleSectionMobileClick('section3')}>مدیریت کارت عروسی</li>
          <li onClick={() => handleSectionMobileClick('section5')}>لیست مهمان ها</li>
          <li onClick={() => handleSectionMobileClick('MementoPage')}>یادبودها</li>
          <li onClick={() => handleSectionMobileClick('SurveriesPage')}>نظرسنجی‌ها</li>
          {cartData?.template_id ?
            <li className='showCard'>
              <Link to={renderLink()} target="_blank">نمایش کارت
                <img src='/asset/media/pic/icon/invitation-card.png' />
              </Link>
            </li>
            :
            <></>
          }
        </ul>
        <button className='close' onClick={toggleSidebar}>
          <img alt="" className="" src="/asset/media/pic/icon/close.png" />
        </button>
      </nav>
      <main>
        {activeSection === 'section1' && <Section1Page />}
        {activeSection === 'section2' && <Section2Page />}
        {activeSection === 'section3' && <Section3Page />}
        {activeSection === 'section5' && <Section5Page />}
        {activeSection === 'MementoPage' && <MementoPage />}
        {activeSection === 'SurveriesPage' && <SurveriesPage />}
      </main>
    </div>
  );
};

export default DashboardPage;

import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { useAuth } from "../../../../../auth/authContext";
import { Switch } from "@material-ui/core";
import { Link } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const NamesPage = () => {
    const { token, fetchCartData, cartData } = useAuth();

    const initialValues = {
        nameArosFa: cartData?.bride_name || '',
        familyArosFa: cartData?.bride_lastname || '',
        nameArosEn: cartData?.bride_name_eng || '',
        familyArosEn: cartData?.bride_lastname_eng || '',
        nameDamadFa: cartData?.groom_name || '',
        familyDamadFa: cartData?.groom_lastname || '',
        nameDamadEn: cartData?.groom_name_eng || '',
        familyDamadEn: cartData?.groom_lastname_eng || '',
        engName: Number(cartData?.show_eng_names) || 0,
    };

    const validationSchema = Yup.object({
        nameArosFa: Yup.string().required('نام عروس را وارد کنید.'),
        familyArosFa: Yup.string().required('نام خانوادگی عروس را وارد کنید.'),
        nameArosEn: Yup.string().required('نام en عروس را وارد کنید.'),
        familyArosEn: Yup.string().required('نام خانوادگی en عروس را وارد کنید.'),
        nameDamadFa: Yup.string().required('نام داماد را وارد کنید.'),
        familyDamadFa: Yup.string().required('نام خانوادگی داماد را وارد کنید.'),
        nameDamadEn: Yup.string().required('نام en داماد را وارد کنید.'),
        familyDamadEn: Yup.string().required('نام خانوادگی en داماد را وارد کنید.'),
    });

    const handleSubmit = (values) => {
        axios.post(`${BASE_URL}/api/licence/current`,
            {
                bride_name: values?.nameArosFa,
                bride_lastname: values?.familyArosFa,
                bride_name_eng: values?.nameArosEn,
                bride_lastname_eng: values?.familyArosEn,
                groom_name: values?.nameDamadFa,
                groom_lastname: values?.familyDamadFa,
                groom_name_eng: values?.nameDamadEn,
                groom_lastname_eng: values?.familyDamadEn,
                show_eng_names: values?.engName,
            }
            ,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                toast.success(response?.data?.message);
                fetchCartData();
            })
            .catch(error => {
                toast.error(error?.response?.data?.message);
            });
    };

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({
                values,
                setFieldValue
            }) => (
                <Form className="formInformation">
                    <Grid container spacing={2}>
                        <Grid sx={{ display: "flex" }} item xs={12}>
                            <Typography variant="h6">نام عروس :</Typography>

                        </Grid>

                        <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={6}>
                            <Field type="text" id="nameArosFa" name="nameArosFa" placeholder="صبا" />
                            <ErrorMessage name="nameArosFa" component="p" style={{ color: "red", fontSize: "12px" }} />
                        </Grid>
                        <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={6}>
                            <Field type="text" id="familyArosFa" name="familyArosFa" placeholder="ایرانی" />
                            <ErrorMessage name="familyArosFa" component="p" style={{ color: "red", fontSize: "12px" }} />

                        </Grid>
                        <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={6}>
                            <Field type="text" id="nameArosEn" name="nameArosEn" placeholder="Saba" />
                            <ErrorMessage name="nameArosEn" component="p" style={{ color: "red", fontSize: "12px" }} />
                        </Grid>

                        <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={6}>
                            <Field type="text" id="familyArosEn" name="familyArosEn" placeholder="Irani" />
                            <ErrorMessage name="familyArosEn" component="p" style={{ color: "red", fontSize: "12px" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">نام داماد :</Typography>
                        </Grid>
                        <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={6}>
                            <Field type="text" id="nameDamadFa" name="nameDamadFa" placeholder="آرمین" />
                            <ErrorMessage name="nameDamadFa" component="p" style={{ color: "red", fontSize: "12px" }} />
                        </Grid>
                        <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={6}>
                            <Field type="text" id="familyDamadFa" name="familyDamadFa" placeholder="احمدی" />
                            <ErrorMessage name="familyDamadFa" component="p" style={{ color: "red", fontSize: "12px" }} />
                        </Grid>
                        <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={6}>
                            <Field type="text" id="nameDamadEn" name="nameDamadEn" placeholder="Armin" />
                            <ErrorMessage name="nameDamadEn" component="p" style={{ color: "red", fontSize: "12px" }} />
                        </Grid>
                        <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={6}>
                            <Field type="text" id="familyDamadEn" name="familyDamadEn" placeholder="Ahmadi" />
                            <ErrorMessage name="familyDamadEn" component="p" style={{ color: "red", fontSize: "12px" }} />
                        </Grid>

                        <Grid item xs={12} sx={{ position: "relative", paddingBottom: "16px", marginTop: "5px" }}>
                            {cartData?.licence_id === 1 ?
                                <Box className="licenseDisable">
                                    <Link to="/plan/3" className="licenseLink">اپدیت پکیج</Link>
                                </Box>
                                : ""
                            }
                            <Typography variant="p">نمایش نام‌‌ها به صورت انگلیسی</Typography>
                            <Switch
                                name="engName"
                                value="N"
                                checked={values.engName === 1}
                                onChange={(event, checked) => {
                                    setFieldValue("engName", checked ? 1 : 0);
                                }}
                            />
                        </Grid>

                        <Grid sx={{ display: "flex", justifyContent: "flex-end" }} item xs={12}>
                            <button className="saveInformation" type="submit">ذخیره</button>
                        </Grid>
                    </Grid>

                </Form>
            )}
        </Formik>
    );
};


export default NamesPage;
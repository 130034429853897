import { Link, useNavigate } from "react-router-dom";
// import "../Plan/PlanPage.css"
import { Box, Grid, Typography } from "@material-ui/core";
import Layout from "../Layout/Layout"
import { useFormik } from "formik";
import * as Yup from "yup";
import { Toast, toast } from "react-toastify";
import axios from "axios";
import Input from "../common/input";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const ContactUsPage = () => {

    const handleSubmit = (values) => {
        axios.post(`${BASE_URL}/api/xxxx`, {
            name: "name",
            text: "some text",
        })
            .then(response => {
                toast.success(response?.data?.message);
                // fetchCartData();
            })
            .catch(error => {
                toast.success("با موفقیت ثبت شد.");
            });
    };


    return (
        <Layout>
            <main className="planPage">
                <img alt="" className="flowerLeft" src="/asset/media/pic/flower-header1.png" />
                <Grid container style={{ marginBottom: '100px' }}>
                    <Grid item lg={12} xs={10}>
                        <Typography variant="h3">ارتباط با ما</Typography>
                    </Grid>

                    <Grid>
                        <form className="contactUsForm" onSubmit={handleSubmit}>
                            <label>نام</label>
                            <input name="name" label="نام" type="name" />
                            <label>متن</label>
                            <textarea name="text" label="متن" />

                            <button className="submitBtn" type="submit" >ثبت</button>
                        </form>
                    </Grid>

                    <Grid item lg={12} xs={10}>
                        <Typography variant="h6">راه‌های ارتباطی:</Typography>
                    </Grid>
                    <Grid item lg={12} xs={10}>
                        <Typography variant="p" style={{ marginLeft: "15px" }}>تماس و واتس اپ:</Typography>
                        <Typography variant="p">09101428464</Typography>
                    </Grid>
                    <Grid item lg={12} xs={10}>
                        <Typography variant="p" style={{ marginLeft: "15px" }}>ایمیل:</Typography>
                        <Typography variant="p">onlinearoosi@gmail.com</Typography>
                    </Grid>
                    <Grid item lg={12} xs={10}>
                        <Typography variant="p" style={{ marginLeft: "15px" }}>آدرس:</Typography>
                        <Typography variant="p">تهران - نازی آباد - خیابان عراقی - پلاک 15</Typography>
                    </Grid>
                </Grid>
            </main>
        </Layout>
    );
};

export default ContactUsPage;

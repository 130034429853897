import { Box, Grid, Typography } from "@mui/material";
import "../../../DashboardPage.css";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../auth/authContext";
import { Link } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const TalarPage = () => {
    const { token, fetchCartData, cartData } = useAuth();

    const initialValues = {
        nameTalar: cartData?.saloon_name || '',
        addressTalar: cartData?.saloon_address || '',
        waze: cartData?.waze || '',
        map: cartData?.google_map || '',
        balad: cartData?.balad || '',
        neshan: cartData?.neshan || '',
    };

    const validationSchema = Yup.object({
        nameTalar: Yup.string().required('اسم تالار را وارد کنید.'),
        addressTalar: Yup.string().required('آدرس تالار را وارد کنید.'),
        waze: Yup.string(),
        map: Yup.string(),
        balad: Yup.string(),
        neshan: Yup.string(),
    });

    const handleSubmit = (values) => {
        axios.post(`${BASE_URL}/api/licence/current`,
            {
                saloon_name: values?.nameTalar,
                saloon_address: values?.addressTalar,
                waze: values?.waze,
                google_map: values?.map,
                balad: values?.balad,
                neshan: values?.neshan,
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                toast.success(response?.data?.message);
                fetchCartData();
            })
            .catch(error => {
                toast.error(error?.response?.data?.message);
            });
    };

    return (
        <>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                <Form className="formInformation">

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5">مشخصات تالار</Typography>
                        </Grid>

                        <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={12}>
                            <label htmlFor="nameTalar">نام تالار</label>
                            <Field type="text" id="nameTalar" name="nameTalar" />
                            <ErrorMessage name="nameTalar" component="p" style={{ color: "red", fontSize: "12px" }} />
                        </Grid>
                        <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={12}>
                            <label htmlFor="addressTalar">نشانی تالار</label>
                            <Field type="address" id="addressTalar" name="addressTalar" />
                            <ErrorMessage name="addressTalar" component="p" style={{ color: "red", fontSize: "12px" }} />
                        </Grid>

                        <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={12}>
                            <Typography variant="p">هرکدام از برنامه های مسیریابی که نیاز دارید لینک تالار را داخل آن قرار دهید در غیر این صورت خالی بگذارید</Typography>
                            <a href="/blog/find-location" target="_blank" rel="noopener noreferrer" style={{ color: "#5758BB" }}>
                                آموزش پیدا کردن لینک مکان در برنامه‌های مسیریاب
                            </a>
                        </Grid>

                        <Grid container spacing={2} sx={{ position: "relative", width: "100%", marginTop: "0" }}>
                            {cartData?.licence_id === 1 ?
                                <Box className="licenseDisable">
                                    <Link to="/plan/3" className="licenseLink">اپدیت پکیج</Link>
                                </Box>
                                : ""
                            }
                            <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={12} md={6}>
                                <label htmlFor="waze">waze</label>
                                <Field type="address" id="waze" name="waze" />
                                <ErrorMessage name="waze" component="p" style={{ color: "red", fontSize: "12px" }} />
                            </Grid>
                            <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={12} md={6}>
                                <label htmlFor="map">map</label>
                                <Field type="address" id="map" name="map" />
                                <ErrorMessage name="map" component="p" style={{ color: "red", fontSize: "12px" }} />
                            </Grid>
                            <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={12} md={6}>
                                <label htmlFor="balad">بلد</label>
                                <Field type="address" id="balad" name="balad" />
                                <ErrorMessage name="balad" component="p" style={{ color: "red", fontSize: "12px" }} />
                            </Grid>
                            <Grid sx={{ display: "flex", flexDirection: 'column' }} item xs={12} md={6}>
                                <label htmlFor="neshan">نشان</label>
                                <Field type="address" id="neshan" name="neshan" />
                                <ErrorMessage name="neshan" component="p" style={{ color: "red", fontSize: "12px" }} />
                            </Grid>
                        </Grid>


                        <Grid sx={{ display: "flex", justifyContent: "flex-end", marginTop: "15px" }} item xs={12}>
                            <button className="saveInformation" type="submit">ذخیره</button>
                        </Grid>
                    </Grid>

                </Form>
            </Formik>
        </>
    );
};


export default TalarPage;
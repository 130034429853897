import React, { Component } from 'react';
import Typed from 'typed.js';

class TypingComponent extends Component {
  constructor(props) {
    super(props);
    this.typed = null;
  }

  componentDidMount() {
    // Your Typed.js configuration options
    const options = {
      strings: this.props.strings, // Use the strings prop here
      typeSpeed: 50, // typing speed in milliseconds
      backSpeed: 25, // backspacing speed in milliseconds
      loop: true, // loop the animation
    };

    // Create a new Typed instance
    this.typed = new Typed(this.el, options);
  }

  componentWillUnmount() {
    // Make sure to destroy the Typed instance to prevent memory leaks
    if (this.typed) {
      this.typed.destroy();
    }
  }

  render() {
    return (
      <div>
        <span ref={(el) => { this.el = el; }} style={{ fontSize: 44 }}></span>
      </div>
    );
  }
}

export default TypingComponent;

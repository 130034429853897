export const blogs = [
    {
        id: "0",
        name: 'one',
        desp: "lorem1"
    },
    {
        id: "1",
        name: 'two',
        desp: "lorem2"
    },
    {
        id: "2",
        name: 'tree',
        desp: "lorem3"
    },
];

export const plans = [
    {
        id: 1,
        image: "/asset/media/image/aros-damad.jpg",
        title: 'برنز',
        details: ['شخصی سازی کارت',
            "لینک اختصاصی کارت",
            'اشتراک نامحدود لینک'],
        planOption: [
            {
                id: 1,
                option: "شخصی سازی اطلاعات کارت",
                disable: "",
            },
            {
                id: 2,
                option: "لینک اختصاصی کارت شما جهت اشتراک گذاری",
                disable: "",
            },
            {
                id: 3,
                option: "امکان اشتراک گذاری نامحدود لینک",
                disable: "",
            },
            {
                id: 4,
                option: "ثبت و مدیریت یادبودها",
                disable: "disable",
            },
            {
                id: 5,
                option: "امکان ساخت آلبوم عکس",
                disable: "disable",
            },
            {
                id: 6,
                option: "امکان  قرارگیری عکس تکی عروس و داماد",
                disable: "disable",
            },
            {
                id: 7,
                option: "امکان نظرسنجی در مورد شرکت یا عدم شرکت مهمان‌ها",
                disable: "disable",
            },
            {
                id: 8,
                option: "مدیریت مهمان ها از طریق پنل",
                disable: "disable",
            },
            {
                id: 9,
                option: "ارسال پیامک با لینک اختصاصی برای هر مهمان",
                disable: "disable",
            },
            {
                id: 10,
                option: "اطمینان از بازدید کارت توسط هر مهمان",
                disable: "disable",
            },
            {
                id: 11,
                option: "اشتراک گذاری لوکیشن تالار و امکان استفاده در برنامه‌های مسیریابی",
                disable: "disable",
            },
            {
                id: 12,
                option: "استفاده از قالب‌های تشریفات",
                disable: "disable",
            },
            {
                id: 13,
                option: "پیامک یادآوری روز قبل از مراسم برای مهمان ها",
                disable: "disable",
            },
            {
                id: 14,
                option: "عدم محدودیت در تعداد کارت‌های ارسالی",
                disable: "disable",
            },
            {
                id: 15,
                option: "استفاده از قالب‌های لوکس",
                disable: "disable",
            },
        ],
        off: "رایگان",
    },
    // {
    //     id: 2,
    //     image: "/asset/media/image/aros-flower.jpg",
    //     title: 'تشریفات',
    //     details: ['ارسال پیامک دعوت',
    //         "مسیریابی با اپ",
    //         'یادبودها',
    //         "عکس عروس داماد",
    //         'مدیریت مهمان‌ها',
    //         "اطمینان از بازدید کارت‌ها"],
    //     cost: "800,000 تومان",
    //     planOption: [
    //         {
    //             id: 1,
    //             option: "شخصی سازی اطلاعات کارت",
    //             disable: "false",
    //         },
    //         {
    //             id: 2,
    //             option: "لینک اختصاصی کارت شما جهت اشتراک گذاری",
    //             disable: "",
    //         },
    //         {
    //             id: 3,
    //             option: "امکان اشتراک گذاری نامحدود لینک",
    //             disable: "",
    //         },
    //         {
    //             id: 4,
    //             option: "ثبت و مدیریت یادبودها",
    //             disable: "",
    //         },
    //         {
    //             id: 5,
    //             option: "امکان ساخت آلبوم عکس",
    //             disable: "disable",
    //         },
    //         {
    //             id: 6,
    //             option: "امکان  قرارگیری عکس تکی عروس و داماد",
    //             disable: "",
    //         },
    //         {
    //             id: 7,
    //             option: "امکان نظرسنجی در مورد شرکت یا عدم شرکت مهمان‌ها",
    //             disable: "",
    //         },
    //         {
    //             id: 8,
    //             option: "مدیریت مهمان ها از طریق پنل",
    //             disable: "",
    //         },
    //         {
    //             id: 9,
    //             option: "ارسال پیامک با لینک اختصاصی برای هر مهمان (حداکثر 100 کارت)",
    //             disable: "",
    //         },
    //         {
    //             id: 10,
    //             option: "اطمینان از بازدید کارت توسط هر مهمان",
    //             disable: "",
    //         },
    //         {
    //             id: 11,
    //             option: "اشتراک گذاری لوکیشن تالار و امکان استفاده در برنامه‌های مسیریابی",
    //             disable: "",
    //         },
    //         {
    //             id: 12,
    //             option: "استفاده از قالب‌های تشریفات",
    //             disable: "",
    //         },
    //         {
    //             id: 13,
    //             option: "پیامک یادآوری روز قبل از مراسم برای مهمان ها",
    //             disable: "disable",
    //         },
    //         {
    //             id: 14,
    //             option: "عدم محدودیت در تعداد کارت‌های ارسالی",
    //             disable: "disable",
    //         },
    //         {
    //             id: 15,
    //             option: "استفاده از قالب‌های لوکس",
    //             disable: "disable",
    //         },
    //     ],
    //     off: "499,000 تومان",
    // },
    {
        id: 3,
        image: "/asset/media/image/aros-halqe.jpg",
        title: 'لوکس',
        details: ['ارسال پیامک دعوت',
            "مسیریابی با اپ",
            "آلبوم عکس",
            "پیامک یادآوری روز قبل مراسم",
            "عکس عروس داماد",
            'مدیریت مهمان‌ها',
            "اطمینان از بازدید کارت‌ها"],
        cost: "1,600,000 تومان",
        planOption: [
            {
                id: 1,
                option: "شخصی سازی اطلاعات کارت",
                disable: "false",
            },
            {
                id: 2,
                option: "لینک اختصاصی کارت شما جهت اشتراک گذاری",
                disable: "",
            },
            {
                id: 3,
                option: "امکان اشتراک گذاری نامحدود لینک",
                disable: "",
            },
            {
                id: 4,
                option: "ثبت و مدیریت یادبودها",
                disable: "",
            },
            // {
            //     id: 5,
            //     option: "امکان ساخت آلبوم عکس",
            //     disable: "disable",
            // },
            // {
            //     id: 6,
            //     option: "امکان  قرارگیری عکس تکی عروس و داماد",
            //     disable: "disable",
            // },
            {
                id: 7,
                option: "امکان نظرسنجی در مورد شرکت یا عدم شرکت مهمان‌ها",
                disable: "",
            },
            {
                id: 8,
                option: "مدیریت مهمان ها از طریق پنل",
                disable: "",
            },
            {
                id: 9,
                option: "ارسال پیامک با لینک اختصاصی برای هر مهمان (1000 عدد)",
                disable: "",
            },
            {
                id: 10,
                option: "اطمینان از بازدید کارت توسط هر مهمان",
                disable: "",
            },
            {
                id: 11,
                option: "اشتراک گذاری لوکیشن تالار و امکان استفاده در برنامه‌های مسیریابی",
                disable: "",
            },
            // {
            //     id: 12,
            //     option: "استفاده از قالب‌های تشریفات",
            //     disable: "",
            // },
            {
                id: 13,
                option: "پیامک یادآوری روز قبل از مراسم برای مهمان ها",
                disable: "",
            },
            {
                id: 14,
                option: "عدم محدودیت در تعداد کارت‌های ارسالی",
                disable: "",
            },
            {
                id: 15,
                option: "استفاده از قالب‌های لوکس",
                disable: "",
            },
        ],
        off: "599,000 تومان",

    },
];
// export const plansOption = [
//     {
//         id: 258,
//         details: "",
//     },
//     {
//         id: 1,
//         details: "شخصی سازی اطلاعات کارت",
//     },
//     {
//         id: 2,
//         details: "ایجاد و مدیریت یادبودها",
//     },
//     {
//         id: 1,
//         details: "لینک اختصاصی کارت دعوت جهت اشتراک گذاری",
//     },
//     {
//         id: 1,
//         details: "امکان اشتراک گذاری نامحدود لینک دعوت",
//     },
//     {
//         id: 1,
//         details: "ارسال لینک اختصاصی برای هرمهمان با پیامک",
//     },
//     {
//         id: 1,
//         details: "ارسال لینک اختصاصی برای هرمهمان با پیامک",
//     },
// ];

export const optionData = [
    {
        id: 1,
        image: "/asset/media/icon/icon-dinner.webp",
        title: 'بازدیدها',
        number: 2500
    },
    {
        id: 2,
        image: "/asset/media/icon/icon-wedding-save.webp",
        title: 'کارت‌های منتشرشده',
        number: 2500
    },
    {
        id: 3,
        image: "/asset/media/icon/icons-bride.png",
        title: 'کاربرها',
        number: 2500
    },
    {
        id: 4,
        image: "/asset/media/icon/icon-comment.png",
        title: 'یادبودها ',
        number: 2500
    },
];

export const dashboardCardData = [
    {
        id: 1,
        borderColor: "#4834d4",
        backgroundColor: "#686de0",
        image: "/asset/media/icon/wedding-card-close.png",
        title: 'کارت‌های ارسالی',
        number: 25
    },
    {
        id: 2,
        borderColor: "#22a6b3",
        backgroundColor: "#7ed6df",
        image: "/asset/media/icon/wedding-card.png",
        title: "کارت‌های بازدیدشده",
        number: 82
    },
    {
        id: 3,
        borderColor: "#6ab04c",
        backgroundColor: "#badc58",
        image: "/asset/media/icon/cheers-white.png",
        title: "مهمان‌ها",
        number: 61
    },
    {
        id: 4,
        borderColor: "#eb4d4b",
        backgroundColor: "#ff7979",
        image: "/asset/media/icon/wedding-day.png",
        title: "روزهای باقی‌مانده",
        number: 32
    },
];

export const trees = [
    {
        id: 1,
        image: "/asset/media/pic/tree/tree1.svg",
    },
    {
        id: 2,
        image: "/asset/media/pic/tree/tree2.svg",
    },
    {
        id: 3,
        image: "/asset/media/pic/tree/tree3.svg",
    },
    {
        id: 4,
        image: "/asset/media/pic/tree/tree4.svg",
    },
    {
        id: 5,
        image: "/asset/media/pic/tree/tree5.svg",
    },
    {
        id: 6,
        image: "/asset/media/pic/tree/tree6.svg",
    },
    {
        id: 7,
        image: "/asset/media/pic/tree/tree7.svg",
    },
    {
        id: 8,
        image: "/asset/media/pic/tree/tree8.svg",
    },
    {
        id: 9,
        image: "/asset/media/pic/tree/tree9.svg",
    },
    {
        id: 10,
        image: "/asset/media/pic/tree/tree10.svg",
    },
    {
        id: 11,
        image: "/asset/media/pic/tree/tree11.svg",
    },
    {
        id: 12,
        image: "/asset/media/pic/tree/tree12.svg",
    },
];

export const arosDamad = [
    {
        id: 1,
        title: 'میلاد و فاطمه',
    },
    {
        id: 2,
        title: 'مهشید و علی',
    },
    {
        id: 3,
        title: 'آرام و پارسا',
    },
    {
        id: 4,
        title: 'سارا و ماهان',
    },
    {
        id: 5,
        title: 'ثمین و اشکان',
    },
    {
        id: 6,
        title: 'صبا و آرمین',
    },
    {
        id: 7,
        title: 'زهرا و رضا',
    },
    {
        id: 8,
        title: 'راحله و داوود',
    },
    {
        id: 9,
        title: 'ریحانه و محسن',
    },
    {
        id: 10,
        title: 'کیمیا و مهرشاد',
    },
    {
        id: 11,
        title: 'زینب و نعیم',
    },
    {
        id: 12,
        title: 'ساحل و مهرداد',
    },
    {
        id: 13,
        title: 'میلاد و فاطمه',
    },
    {
        id: 14,
        title: 'مهشید و علی',
    },
    {
        id: 15,
        title: 'آرام و پارسا',
    },
    {
        id: 16,
        title: 'سارا و ماهان',
    },
    {
        id: 17,
        title: 'ثمین و اشکان',
    },
    {
        id: 18,
        title: 'صبا و آرمین',
    },
    {
        id: 19,
        title: 'زهرا و رضا',
    },
    {
        id: 20,
        title: 'راحله و داوود',
    },
    {
        id: 21,
        title: 'ریحانه و محسن',
    },
    {
        id: 22,
        title: 'کیمیا و مهرشاد',
    },
    {
        id: 23,
        title: 'زینب و نعیم',
    },
    {
        id: 24,
        title: 'ساحل و مهرداد',
    },
    {
        id: 25,
        title: 'میلاد و فاطمه',
    },
    {
        id: 26,
        title: 'مهشید و علی',
    },
    {
        id: 27,
        title: 'آرام و پارسا',
    },
    {
        id: 28,
        title: 'سارا و ماهان',
    },
    {
        id: 29,
        title: 'ثمین و اشکان',
    },
    {
        id: 30,
        title: 'صبا و آرمین',
    },
    {
        id: 31,
        title: 'زهرا و رضا',
    },
    {
        id: 32,
        title: 'راحله و داوود',
    },
    {
        id: 33,
        title: 'ریحانه و محسن',
    },
    {
        id: 34,
        title: 'کیمیا و مهرشاد',
    },
    {
        id: 35,
        title: 'زینب و نعیم',
    },
    {
        id: 36,
        title: 'ساحل و مهرداد',
    },
    {
        id: 37,
        title: 'میلاد و فاطمه',
    },
    {
        id: 38,
        title: 'مهشید و علی',
    },
    {
        id: 39,
        title: 'آرام و پارسا',
    },
    {
        id: 40,
        title: 'سارا و ماهان',
    },
    {
        id: 41,
        title: 'ثمین و اشکان',
    },
    {
        id: 42,
        title: 'صبا و آرمین',
    },
    {
        id: 43,
        title: 'زهرا و رضا',
    },
    {
        id: 44,
        title: 'راحله و داوود',
    },
    {
        id: 45,
        title: 'ریحانه و محسن',
    },
    {
        id: 46,
        title: 'کیمیا و مهرشاد',
    },
    {
        id: 47,
        title: 'زینب و نعیم',
    },
    {
        id: 48,
        title: 'ساحل و مهرداد',
    },
    {
        id: 49,
        title: 'میلاد و فاطمه',
    },
    {
        id: 50,
        title: 'مهشید و علی',
    },
    {
        id: 50,
        title: 'آرام و پارسا',
    },
    {
        id: 52,
        title: 'سارا و ماهان',
    },
    {
        id: 53,
        title: 'ثمین و اشکان',
    },
    {
        id: 54,
        title: 'صبا و آرمین',
    },
    // {
    //     id: 55,
    //     title: 'زهرا و رضا',
    // },
    // {
    //     id: 56,
    //     title: 'راحله و داوود',
    // },
    // {
    //     id: 57,
    //     title: 'ریحانه و محسن',
    // },
    // {
    //     id: 58,
    //     title: 'کیمیا و مهرشاد',
    // },
    // {
    //     id: 59,
    //     title: 'زینب و نعیم',
    // },
    // {
    //     id: 60,
    //     title: 'ساحل و مهرداد',
    // },
    // {
    //     id: 61,
    //     title: 'میلاد و فاطمه',
    // },
    // {
    //     id: 62,
    //     title: 'مهشید و علی',
    // },
    // {
    //     id: 63,
    //     title: 'آرام و پارسا',
    // },
    // {
    //     id: 64,
    //     title: 'سارا و ماهان',
    // },
    // {
    //     id: 65,
    //     title: 'ثمین و اشکان',
    // },
    // {
    //     id: 66,
    //     title: 'صبا و آرمین',
    // },
    // {
    //     id: 67,
    //     title: 'زهرا و رضا',
    // },
    // {
    //     id: 68,
    //     title: 'راحله و داوود',
    // },
    // {
    //     id: 69,
    //     title: 'ریحانه و محسن',
    // },
    // {
    //     id: 70,
    //     title: 'کیمیا و مهرشاد',
    // },
    // {
    //     id: 71,
    //     title: 'زینب و نعیم',
    // },
    // {
    //     id: 72,
    //     title: ' محد  همین سادات ساحل و مهرداد',
    // },
];

export const comments = [
    { name: 'عمه اعظم', comment: 'انشالله خوشبخت و عاقبت به خیر باشید.' },
    { name: 'دایی رضا', comment: 'با آرزوی خوشبختی و سعادت برای شما عزیزانم.' },
    { name: 'زن عمو هانیه', comment: 'خوشبختی شما عزیزانم آرزوی همیشگی ماست.' },
    { name: '22222زن عمو هانیه', comment: 'خوشبختی شما عزیزانم آرزوی همیشگی ماست.' },
];

export const exampleCartData =[
    {
        "id": 19,
        "wedding_id": 5,
        "number_of_guests": 4,
        // "code": "NxVb",
        "wedding": {
            "id": 5,
            "client_id": 4,
            "licence_id": 2,
            "bride_name": "نبات",
            "bride_name_eng": "nabat",
            "bride_lastname": "ایزدی",
            "groom_name": "عماد",
            "groom_name_eng": "emad",
            "groom_lastname": "فرهادی",
            "description": "\u0627\u06cc \u06a9\u0627\u0634 \u0639\u0634\u0642 \u0631\u0627 \u0632\u0628\u0627\u0646 \u0633\u062e\u0646 \u0628\u0648\u062f\n    \u0622\u063a\u0627\u0632 \u0632\u0646\u062f\u06af\u06cc \u0645\u0634\u062a\u0631\u06a9 \u062e\u0648\u06cc\u0634 \u0631\u0627 \u062c\u0634\u0646 \u0645\u06cc \u06af\u06cc\u0631\u0646\u062f\n    \u0628\u0627 \u062a\u0634\u0631\u06cc\u0641 \u0641\u0631\u0645\u0627\u06cc\u06cc \u062e\u0648\u062f \u0633\u0631\u0627\u0641\u0631\u0627\u0632\u0645\u0627\u0646 \u0641\u0631\u0645\u0627\u06cc\u06cc\u062f.",
            "date": "2024-04-25",
            "from_time": "19:00:00",
            "to_time": "23:00:00",
            "foods": "شام و شیرینی و شادی",
            "deleted_at": null,
            "created_at": "2024-04-20T18:54:41.000000Z",
            "updated_at": "2024-04-25T07:57:33.000000Z",
            "saloon_name": "قصر آریا",
            "saloon_address": "میدان رسالت، خیابان شهید مدنی، پایین تر از ایستگاه مترو فدک",
            "waze": "/waze",
            "google_map": "/map",
            "balad": "/balad",
            "neshan": "/neshan",
            "bride_avatar": null,
            "groom_avatar": null,
            "background": null,
            "bride_lastname_eng": "eizadi",
            "groom_lastname_eng": "farhadi",
            "slug": "nabat-emad",
            "any_sms_send": 0,
            "active": 1,
            "show_eng_names": 0,
            "show_survey": 1,
            "show_memento": 1
        }
    }
]
import { Link } from "react-router-dom";
import { useCart, useCartActions } from "../../Providers/CartProvider";
import * as data from "../../data";
import { Toast, toast } from "react-toastify";
import { useState } from "react";

// function checkInCart(cart, product) {
//     return cart.find(c => c.id === product.id)
// }

const PlansComponent = () => {
    // const { cart } = useCart();
    // const dispatch = useCartActions();

    // const addPlanHandler = (plan) => {
    //     dispatch({ type: "ADD_TO_CART", payload: plan })
    //     toast.success(`با موفقیت به سبد خرید شما اضافه شد ${plan.name}`)
    // }
    const [hoveredPlan, setHoveredPlan] = useState(null);
    const [displayCount, setDisplayCount] = useState(3);
    const animationTypes = [
        'zoom-in-left',
        //  'fade-up',
        'zoom-in-right'
    ];

    return (
        <section className="planList">
            {data?.plans?.map((plan, index) => {
                const isPlanHovered = hoveredPlan === plan.id;
                const animationType = animationTypes[index % animationTypes.length];

                return (
                    <Link
                        to={`/plan/${plan.id}`}
                        className="plan"
                        key={plan.id}
                        data-aos={animationType}
                        data-aos-duration="1000"
                        onMouseEnter={() => {
                            setHoveredPlan(plan.id);
                            setDisplayCount(plan.details.length);
                        }}
                        onMouseLeave={() => {
                            setHoveredPlan(null);
                            setDisplayCount(3);
                        }}
                    >
                        <img className="imageBg" src={plan.image} alt={plan.title} />
                        <div className="content">
                            <h3 className="title">{plan.title}</h3>
                            <p className="cost">{plan.cost}</p>
                            <p className="offer">{plan.off}</p>
                            <div className="details">
                                {plan?.details?.slice(0, isPlanHovered ? displayCount : 3)?.map((detail, index) => {
                                    return (
                                        <p key={index}>+ {detail}</p>
                                    );
                                })}
                            </div>
                            <button className="more">جزییات بیشتر</button>
                            {/* <button
                                    onClick={() => addPlanHandler(plan)}
                                >{checkInCart(cart, plan) ? "ادامه مراحل خرید" : 'انخاب پلن'}</button> */}
                        </div>
                    </Link>
                );
            })}
        </section>
    );
};

export default PlansComponent;

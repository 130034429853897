// authContext.js
import axios from 'axios';
import { useEffect } from 'react';
import { createContext, useContext, useReducer, useState } from 'react';
import { toast } from 'react-toastify';

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const AuthContext = createContext();

const initialState = {
  // user: null,
  // token: null,
  // isAuthenticated: false,
  token: localStorage.getItem('token') || null,
  isAuthenticated: localStorage.getItem('isAuthenticated') || false,
  cartData: null,
}
// function authReducer(state, action) {
//   switch (action.type) {
//     case "login": return { token: action.payload, isAuthenticated: true };
//     case "logout": return { token: null, isAuthenticated: false };
//     default: throw new Error("unknown action");
//   }
// }

function authReducer(state, action) {
  switch (action.type) {
    case "login":
      localStorage.setItem('token', action.payload);
      localStorage.setItem('isAuthenticated', true);
      return { token: action.payload, isAuthenticated: true };
    case "logout":
      localStorage.removeItem('token');
      localStorage.removeItem('isAuthenticated');
      return { token: null, isAuthenticated: false };
    case "setCartData":
      return { ...state, cartData: action.payload };
    default:
      throw new Error("unknown action");
  }
}

export default function AuthProvider({ children }) {
  const [{ token, isAuthenticated, cartData }, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    // Check if the token is expired or not here
    // If expired, dispatch logout action
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchCartData();
    }
  }, [isAuthenticated, token]);

  const fetchCartData = () => {
    axios.get(`${BASE_URL}/api/licence/current`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        dispatch({ type: "setCartData", payload: response?.data?.wedding });
      })
      .catch(error => {
        // Handle error
      });
  };

  const login = (status, token) => {
    if (status) dispatch({ type: "login", payload: token })
  };

  const logout = () => {
    axios.get(`${BASE_URL}/api/auth/logout`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        toast.success(response?.data?.message);
        dispatch({ type: "logout" });
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          // If the error is due to unauthorized access (401), dispatch logout action
          dispatch({ type: "logout" });
        } else {
          // Handle other errors
          toast.error(error?.response?.data?.message);
        }
      });
  };



  return (
    <AuthContext.Provider value={{ token, isAuthenticated, cartData, login, logout, fetchCartData }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

import "./Footer.css"

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='title'>
                <span className='line'></span>
                <h2>در بهترین روز زندگیتان کنارتان هستیم</h2>
            </div>
            <p className='desc'>هدف ما در تیم عروسی آنلاین این است که دغدغه شما را برای مهم ترین روز زندگیتان کم کنیم تا در آرامش یکی از کارهای زمان‌بر را برای شما انجام دهیم و مطمعن باشید همه کسانی که برای شما مهم هستند در این روز مهم کنار شما خواهند بود</p>
            <h3 className='aroosiOnline'>aroosi.online</h3>
            <img className='flowerImg' src='/asset/media/pic/flower-bottom-footer.png' />
        </footer>
    );
};

export default Footer;

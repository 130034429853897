const Section2Page = ({cartData}) => {
    return (
        <div className="sectionTwo">

            <h4 className="talar" data-aos="fade-right">{cartData?.saloon_name}</h4>
            <p className="address" data-aos="fade-up">{cartData?.saloon_address}</p>

            {/* <div className="mapouter">
                <div className="gmap_canvas">
                    <iframe
                        title="Google Map"
                        width="100%"
                        height="300"
                        src="https://maps.google.com/maps?q=%D8%AA%D9%87%D8%B1%D8%A7%D9%86&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                    ></iframe>
                    <a href="https://123movies-to.org"></a>
                    <br />
                    <style>
                        {`
          .mapouter {
            position: relative;
            text-align: right;
            height: 300px;
            width: 100%;
          }
          .gmap_canvas {
            overflow: hidden;
            background: none !important;
            height: 300px;
            width: 100%;
          }
        `}
                    </style>
                    <a href="https://www.embedgooglemap.net">embedgooglemap.net</a>
                </div>
            </div> */}

            <div className="apps">
                {
                    cartData?.balad ?
                        <a href={cartData?.balad} target="_blank" data-aos="fade-up" data-aos-duration="500">
                            <img alt="" src='/asset/media/icon/balad.png' className="app" />
                        </a>
                        :
                        <></>
                }
                {
                    cartData?.neshan ?
                        <a href={cartData?.neshan} target="_blank" data-aos="fade-up" data-aos-duration="600">
                            <img alt="" src='/asset/media/icon/neshan.png' className="app" />
                        </a>
                        :
                        <></>
                }
                {
                    cartData?.waze ?
                        <a href={cartData?.waze} target="_blank" data-aos="fade-up" data-aos-duration="700">
                            <img alt="" src='/asset/media/icon/waze.png' className="app" />
                        </a>
                        :
                        <></>
                }
                {
                    cartData?.google_map ?
                        <a href={cartData?.google_map} target="_blank" data-aos="fade-up" data-aos-duration="800">
                            <img alt="" src='/asset/media/icon/google-map.png' className="app" />
                        </a>
                        :
                        <></>
                }
            </div>

        </div>
    );
};


export default Section2Page;
import { useState } from 'react';
import UploadImage from './UploadImage';
import axios from 'axios';
import { Box, Grid, Input, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../auth/authContext';
import { Link } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const GalleryPage = () => {
    const { cartData } = useAuth();

    const categories = [
        { id: 1, name: 'category1', number: "اول", placeholder: "فرمالبته" },
        { id: 2, name: 'category2', number: "دوم", placeholder: "نامزدی" },
        { id: 3, name: 'category3', number: "سوم", placeholder: "سالگرد" },
    ];

    return (
        <div className="formInformation">
            <Box className="formDisable">به زودی...</Box>
            {cartData?.licence_id === 1 ?
                <Box className="licenseDisable">
                    <Link to="/plan/3" className="licenseLink">اپدیت پکیج</Link>
                </Box>
                : ""
            }
            <Grid container spacing={2} sx={{ width: "100%" }}>
                <Grid item xs={12}>
                    <Typography variant="h5">گالری</Typography>
                </Grid>
                {categories?.map((category) => (
                    <GalleryItem {...category} />
                ))}
            </Grid>
        </div>
    );
};

export default GalleryPage;








const GalleryItem = (category) => {
    const { token, fetchCartData } = useAuth();
    const [uploadedImages, setUploadedImages] = useState([]);

    const initialValues = {
        gallery_title: '',
        galleries: '',
    };

    const validationSchema = Yup.object({
        gallery_title: Yup.string().required('فیلد را پر کنید.'),
    });

    const handleImageUpload = (imageFile) => {
        setUploadedImages([...uploadedImages, imageFile]);
    };

    const handleSendImages = async (values) => {
        try {
            const formData = new FormData();
            // formData.append('galleries', uploadedImages);
            uploadedImages.forEach((imageFile) => {
                formData.append('galleries[]', imageFile);
            });

            formData.append("gallery_title", values.gallery_title);


            const response = await axios.post(`${BASE_URL}/api/licence/current`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });
            toast.success(response?.data?.message);
            fetchCartData();
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    };


    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSendImages}>
            <Form className="formInformation">
                <Box key={category?.id} sx={{ width: '100%' }}>
                    <Grid sx={{ display: 'flex', alignItems: 'center', margin: '25px 0 20px' }} item xs={12} md={9}>
                        <label style={{ width: '100px', marginBottom: "0px" }} htmlFor={category?.name}>{`دسته بندی ${category?.number} :`}</label>
                        <Field type="text" id={category?.id} name="gallery_title" placeholder={category?.placeholder} />
                        <ErrorMessage name={category?.name} component="p" />
                    </Grid>
                    <Grid container spacing={2} sx={{ width: "100%" }}>
                        {[1, 2, 3, 4, 5, 6].map((index) => (
                            <Grid
                                key={index}
                                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px 5px 0 !important' }}
                                item
                                xs={6}
                                md={2}
                            >
                                <UploadImage onImageUpload={(imageFile) => handleImageUpload(imageFile)} setUploadedImages={setUploadedImages} />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }} item xs={12}>
                        <button className="saveInformation" type="submit">
                            ذخیره
                        </button>
                    </Grid>
                </Box>
            </Form>
        </Formik>


    );

}
// function numberToPersian(number) {
//     const persian = { 0: "۰", 1: "۱", 2: "۲", 3: "۳", 4: "۴", 5: "۵", 6: "۶", 7: "۷", 
//     8: "۸", 9: "۹" };
//     number = number.toString().split('');
//     let persianNumber = "";
//     for (let i = 0; i < number.length; i++) {
//          number[i] = persian[number[i]];
//     }
//     for (let i = 0; i < number.length; i++) {
//          persianNumber += number[i];
//     }
//     return persianNumber;
// }

// export default numberToPersian;


function numberToPersian(number) {
    // Check if the number is defined
    if (typeof number === 'undefined') {
        return 'Undefined';
    }
    
    const persian = { 0: "۰", 1: "۱", 2: "۲", 3: "۳", 4: "۴", 5: "۵", 6: "۶", 7: "۷", 
    8: "۸", 9: "۹" };
    
    // Convert the number to string
    let strNumber = number.toString();
    
    // Split the string by colon if it exists
    const parts = strNumber.split(':');

    // Convert each part to Persian
    const persianParts = parts.map(part => {
        return part.split('').map(digit => persian[digit]).join('');
    });

    // Join the parts back together with colon
    return persianParts.join(':');
}

export default numberToPersian;


import Layout from "../Layout/Layout";
import { useCart, useCartActions } from "../Providers/CartProvider";
import "./cartPages.css"

const CartPage = () => {
    const { cart } = useCart();
    const dispatch = useCartActions();

    const removeCart = (cartItem) => {
        dispatch({ type: "REMOVE_CARITEM", payload: cartItem })
    }

    if (!cart.length)
        return (
            <Layout>
                <main className="container">
                    <h2> no plan selected</h2>
                </main>
            </Layout>
        );

    return (
        <Layout>
            <main className="container cartContainer">
                <div className="cartItemList">
                    <div className="cartItem">
                        <div>{cart[0].name}</div>
                        <button onClick={() => removeCart(cart[0])}>remove</button>
                    </div>
                </div>
                <div className="cartSummry">cart summry</div>
            </main>
        </Layout>
    );
}

export default CartPage;
import { useNavigate } from "react-router-dom";
// import "../Plan/PlanPage.css"
import { Box, Grid, Typography } from "@material-ui/core";
import { Formik } from "formik";
import Layout from "../Layout/Layout";



const AboutUsPage = () => {

    return (
        <Layout>
            <main className="planPage">
                <img alt="" className="flowerLeft" src="/asset/media/pic/flower-header1.png" />
                <Grid container style={{marginBottom:'100px'}}>
                    <Grid item lg={12} xs={10}>
                        <Typography variant="h3">درباره ما</Typography>
                        <Box style={{ margin: "20px 0" }}>
                        <Typography variant="p" style={{display:'block'}}>رسالت عروسی آنلاین راحتی عروس‌ها و دامادهای عزیز</Typography>
                            <Typography variant="p"> و کمک به برگزاری هرچه بهتر مراسم عروسی شما و دعوت از مهمان‌های شما در سریع‌ترین زمان ممکن است.</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={12} xs={10}>
                        <Typography variant="h6">راه‌های ارتباطی:</Typography>
                    </Grid>
                    <Grid item lg={12} xs={10}>
                        <Typography variant="p" style={{ marginLeft: "15px" }}>تماس و واتس اپ:</Typography>
                        <Typography variant="p">09101428464</Typography>
                    </Grid>
                    <Grid item lg={12} xs={10}>
                        <Typography variant="p" style={{ marginLeft: "15px" }}>ایمیل:</Typography>
                        <Typography variant="p">onlinearoosi@gmail.com</Typography>
                    </Grid>
                    <Grid item lg={12} xs={10}>
                        <Typography variant="p" style={{ marginLeft: "15px" }}>آدرس:</Typography>
                        <Typography variant="p">تهران - نازی آباد - خیابان عراقی - پلاک 15</Typography>
                    </Grid>
                </Grid>
            </main>
        </Layout>
    );
};

export default AboutUsPage;

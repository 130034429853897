import { useState } from 'react';
import { Formik, Form } from 'formik';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../auth/authContext';

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const Section7Page = ({ cartData }) => {

  const { isAuthenticated } = useAuth();
  const surveys = cartData?.surveys;
  const [surveyAnswerId, setSurveyAnswerId] = useState();

  const initialValues = {
    participation: null,
  };

  const handleSubmit = (values, id) => {
    axios.post(`${BASE_URL}/api/survey/participant`, {
      survey_answer_id: Number(surveyAnswerId),
      wedding_id: cartData?.wedding_id,
      code: cartData?.code,
      survey_id: Number(id),
    })
      .then(response => {
        toast.success(response?.data?.message);
        // fetchCartData();
      })
      .catch(error => {
        if (isAuthenticated) {
          toast.error("تنها مهمان‌ها میتوانند در نظرسنجی شرکت کنند.");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };


  return (
    <>
      {surveys?.map((item, index) => (
        <Formik initialValues={initialValues} onSubmit={(values) => handleSubmit(values, item?.id)} key={index}>
          {() => (
            <Form className="sectionSeven" data-aos="zoom-in-left">
              <img src="/asset/media/pic/cart4/flower4-3.svg" className="cardImage1" alt="" />

              <h5 className='title'>{item?.question}</h5>
              <div className="radio-label">
                {item?.survey_answers?.map((item, index) => (
                  <div className="radio-label-item" key={index}>
                    <input
                      type="radio"
                      name="participation"
                      id={`participation_${item?.id}`}
                      value={item?.id}
                      hidden
                      defaultChecked={item?.survey_user_answers?.length !== 0}
                    />
                    <label htmlFor={`participation_${item?.id}`}
                      onClick={() => setSurveyAnswerId(item?.id)}
                    >{item?.answer || "..."}</label>
                  </div>
                ))}
              </div>

              <button type="submit" className='send'>
                ارسال
              </button>
            </Form>
          )}
        </Formik>
      ))}</>
  );
};

export default Section7Page;

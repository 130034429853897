import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Formik, Form, useFormik } from "formik";
import axios from "axios";
import { Switch } from "@material-ui/core";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../auth/authContext";
import * as Yup from 'yup';
import { useState } from "react";
import Input from "../../../../../common/input";
import ModalComponent from "../../../../../components/ModalComponent.tsx";
import { Link } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const ChoosePage = () => {
    const { token, fetchCartData, cartData } = useAuth();
    const [modalOpen, setModalOpen] = useState(false);
    // const [modalOpenEdit, setModalOpenEdit] = useState(false);
    const [disableSurvey, setDisableSurvey] = useState(Number(cartData?.show_survey));

    const initialValues = {
        participate: Number(cartData?.show_survey),
        memorial: Number(cartData?.show_memento),
    };

    const validationSchema = Yup.object({
        participate: Yup.string(),
        memorial: Yup.string(),
    });

    const handleSubmit = (values) => {
        axios.post(`${BASE_URL}/api/licence/current`, {
            show_survey: values?.participate,
            show_memento: values?.memorial,
        },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                toast.success(response?.data?.message);
                fetchCartData();
            })
            .catch(error => {
                toast.error(error?.response?.data?.message);
            });
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    // const toggleModalEdit = () => {
    //     setModalOpenEdit(!modalOpenEdit);
    // };

    // const setEditSurvey = (index, id) => {
    //     const surveyEdit = cartData?.surveys[index];
    //     formik.setValues({
    //         caption: surveyEdit?.question,
    //         option1: surveyEdit?.survey_answers[0]?.answer,
    //         option2: surveyEdit?.survey_answers[1]?.answer,
    //         option3: surveyEdit?.survey_answers[2]?.answer,
    //         option4: surveyEdit?.survey_answers[3]?.answer,
    //         option5: surveyEdit?.survey_answers[4]?.answer,
    //     });
    //     toggleModalEdit();
    // };

    const clearModal = () => {
        formik.setValues({
            caption: '',
            option1: '',
            option2: '',
            option3: '',
            option4: '',
            option5: '',
        });
    };

    const formik = useFormik({
        initialValues: {
            caption: '',
            option1: '',
            option2: '',
            option3: '',
            option4: '',
            option5: '',
        },
        validationSchema: Yup.object({
            caption: Yup.string().required('عنوان را وارد کنید').max(35, "عنوان طولانی است."),
            option1: Yup.string().required('گزینه اول را وارد کنید').max(8, "متن طولانی است."),
            option2: Yup.string().required('گزینه دوم را وارد کنید').max(8, "متن طولانی است."),
            option3: Yup.string().max(8, "متن طولانی است."),
            option4: Yup.string().max(8, "متن طولانی است."),
            option5: Yup.string().max(8, "متن طولانی است."),
        }),
        onSubmit: (values) => {
            const options = [values?.option1, values?.option2, values?.option3, values?.option4, values?.option5].filter(option => option !== null && option !== undefined && option.trim() !== '');
            axios.post(`${BASE_URL}/api/survey/store`,
                {
                    question: values?.caption,
                    answers: options,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    toast.success(response?.data?.message);
                    fetchCartData();
                    toggleModal();
                })
                .catch(error => {
                    toast.error(error?.response?.data?.message);
                });
        }
    });

    const deleteSurvey = (id) => {
        axios.post(`${BASE_URL}/api/delete`,
            {
                id,
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                toast.success(response?.data?.message);
                fetchCartData();
            })
            .catch(error => {
                toast.error(error?.response?.data?.message);
            });
    };

    // const editSurvey = (values) => {
    //     axios.put(`${BASE_URL}/api/edit`,
    //         {
    //             id: values?.id,
    //             caption: values?.caption,
    //             option1: values?.option1,
    //             option2: values?.option2,
    //             option3: values?.option3,
    //             option4: values?.option4,
    //             option5: values?.option5,
    //         },
    //         {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         })
    //         .then(response => {
    //             toast.success(response?.data?.message);
    //             fetchCartData();
    //             toggleModalEdit();
    //         })
    //         .catch(error => {
    //             toast.error(error?.response?.data?.message);
    //         });
    // };

    return (
        <>
            <Formik
                initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({
                    values,
                    setFieldValue
                }) => (
                    <Form className="formInformation" style={{ marginBottom: "5px" }}>
                        {cartData?.licence_id === 1 ?
                            <Box className="licenseDisable">
                                <Link to="/plan/3" className="licenseLink">اپدیت پکیج</Link>
                            </Box>
                            : ""
                        }
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="p">فعال کردن بخش یادبودها</Typography>
                                <Switch
                                    name="memorial"
                                    value="N"
                                    checked={values?.memorial === 1}
                                    onChange={(event, checked) => {
                                        setFieldValue("memorial", checked ? 1 : 0);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="p">فعال کردن بخش نظرسنجی</Typography>
                                <Switch
                                    name="participate"
                                    value="N"
                                    checked={values?.participate === 1}
                                    onChange={(event, checked) => {
                                        setFieldValue("participate", checked ? 1 : 0);
                                        setDisableSurvey(!disableSurvey);
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid sx={{ display: "flex", justifyContent: "flex-end", marginTop: "15px" }} item xs={12}>
                            <button className="saveInformation" type="submit">ذخیره</button>
                        </Grid>
                    </Form>
                )}
            </Formik>

            <Box className="formInformation" style={{ opacity: disableSurvey ? 1 : 0.3 }}>
                {cartData?.licence_id === 1 ?
                    <Box className="licenseDisable">
                        <Link to="/plan/3" className="licenseLink">اپدیت پکیج</Link>
                    </Box>
                    : ""
                }
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                    <Typography variant="h6">نظرسنجی های شما</Typography>
                    <Button
                        onClick={() => {
                            toggleModal();
                            clearModal();
                        }}
                        disabled={!disableSurvey}
                        style={{ borderRadius: "10px" }}
                    >
                        <img src='/asset/media/icon/add.png' style={{ maxWidth: "40px" }} />
                    </Button>
                </Box>
                <Typography variant="body2">برای ایجاد نظرسنجی پرکردن دو گزینه اول و عنوان اجباری است و پرکردن سه گزینه بعدی اختیاری است.</Typography>
                <Typography variant="body2" sx={{ color: "red" }}>* امکان ادیت نظرسنجی وجود ندارد، در وارد کردن اطلاعات دقت کنید.</Typography>
                {cartData?.surveys.map((survey, index) => (
                    <Box key={index} sx={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #474787", padding: "8px 0", marginTop: "20px" }}>
                        <Box>
                            <Typography
                                variant="body1"
                                style={{ whiteSpace: "pre-wrap" }}
                            >
                                <span style={{ color: "#474787", padding: "0 5px", marginLeft: "5px" }}> {index + 1}. </span>
                                <span style={{ color: "#474787" }}> {survey?.question} </span>
                            </Typography>
                            {survey?.survey_answers.map((item, idx) => (
                                <Typography
                                    key={idx}
                                    variant="body1"
                                    style={{ whiteSpace: "pre-wrap", color: "#6c5ce7", paddingRight: "30px" }}
                                >
                                    - {item?.answer}
                                </Typography>
                            ))}
                        </Box>
                        <Box>
                            {/* <Button onClick={() => setEditSurvey(index, survey?.id)} disabled={!disableSurvey} style={{ maxWidth: "30px" }}>
                                <img src='/asset/media/icon/edit.png' style={{ maxWidth: "30px" }} />
                            </Button> */}
                            <Button onClick={() => deleteSurvey(survey?.id)} disabled={!disableSurvey}>
                                <img src='/asset/media/icon/delete.png' style={{ maxWidth: "30px" }} />
                            </Button>
                        </Box>
                    </Box>
                ))}
            </Box>

            <ModalComponent open={modalOpen} setOpen={toggleModal} title='اضافه کردن نظرسنجی'>
                {cartData?.surveys?.length <= 3 ?
                    <Stack spacing={2}>
                        <Input formik={formik} name="caption" id="caption" placeholder="عنوان نظرسنجی" type="text" max={11} />
                        <Input formik={formik} name="option1" id="option1" placeholder="گزینه اول" type="text" label="" max={11} />
                        <Input formik={formik} name="option2" id="option2" placeholder="گزینه دوم" type="text" label="" max={11} />
                        <Input formik={formik} name="option3" id="option3" placeholder="گزینه سوم" type="text" label="" max={11} />
                        <Input formik={formik} name="option4" id="option4" placeholder="گزینه چهارم" type="text" label="" max={11} />
                        <Input formik={formik} name="option5" id="option5" placeholder="گزینه پنجم" type="text" label="" max={11} />
                        <Button
                            style={{
                                backgroundColor: '#474787',
                                color: '#ffffff',
                                padding: "10px 0",
                                borderRadius: "10px",
                            }}
                            variant="contained"
                            onClick={formik.handleSubmit}
                        >
                            افزودن
                        </Button>
                    </Stack>
                    :
                    <Typography variant="p">تعداد زیاد نظرسنجی زیبایی کارت شما را کاهش میدهد. در صورت نیاز به پشتیبانی پیام دهید.</Typography>
                }
            </ModalComponent>

            {/* <ModalComponent open={modalOpenEdit} setOpen={toggleModalEdit} title='ادیت نظرسنجی'>
                <Stack spacing={2}>
                    <Input formik={formik} name="caption" id="caption" placeholder="عنوان نظرسنجی" type="text" max={11} />
                    <Input formik={formik} name="option1" id="option1" placeholder="گزینه اول" type="text" label="" max={11} />
                    <Input formik={formik} name="option2" id="option2" placeholder="گزینه دوم" type="text" label="" max={11} />
                    <Input formik={formik} name="option3" id="option3" placeholder="گزینه سوم" type="text" label="" max={11} />
                    <Input formik={formik} name="option4" id="option4" placeholder="گزینه چهارم" type="text" label="" max={11} />
                    <Input formik={formik} name="option5" id="option5" placeholder="گزینه پنجم" type="text" label="" max={11} />
                    <Button
                        style={{
                            backgroundColor: '#474787',
                            color: '#ffffff',
                            padding: "10px 0",
                            borderRadius: "10px",
                        }}
                        variant="contained"
                        onClick={() => editSurvey(formik.values)}
                    >
                        ادیت
                    </Button>
                </Stack>
            </ModalComponent> */}
        </>
    );
};

export default ChoosePage;

import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';


const Section4Page = ({cartData}) => {
    // Parse the date string from cartData.date into a Date object
    const weddingDate = cartData?.date ? new Date(cartData.date) : null;

    // Calculate the time for countdown (weddingDate + 24 hours + 5000 milliseconds)
    const countdownTime = weddingDate ? weddingDate.getTime() + 15.5 * 3600 * 1000 + 5000 : null;

  return (
    <div style={{ direction: "ltr" }}>
      <FlipClockCountdown  to={countdownTime} className='flip-clock' data-aos="fade-down"
        labels={['روز', 'ساعت', 'دقیقه', 'ثانیه']}
        // labelStyle={{ fontSize: 20, fontWeight: 500, textTransform: 'uppercase', color: "black" }}
        // digitBlockStyle={{ width: 140, height: 160, fontSize: 130, backgroundColor: "#ffb8b8" }}
        // dividerStyle={{ color: 'white', height: 1 }}
        // separatorStyle={{ color: '#ffb8b8', size: '6px' }}
        // duration={0.5}
        >
        <span> </span>
      </FlipClockCountdown>
    </div>
  );
};

export default Section4Page;

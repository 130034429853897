import { Box, Grid } from "@material-ui/core";
import TypingComponent from "../../common/typingComponent";
import { Link } from "react-router-dom";
import * as data from "../../data";

const BannerComponent = () => {
    const typedStrings = ['کارت دعوت عروسی دیجیتال و آنلاین',
        'دعوت از مهمانان مراسم در به صرفه‌ترین زمان',
        'اینجا قراره یه تجربه متفاوت و جذاب داشته باشیم'];

    return (
        <section className="banner">
            <img alt="" className="flowerLeft" src="/asset/media/pic/flower-header1.png" />
            <img alt="" className="flowerRight" src="/asset/media/pic/flower-header2.png" />
            <img alt="" className="flowerBottom" src="/asset/media/pic/flower-header3.png" />
            <Grid container justifyContent="center" alignItems="center">
                <Grid item lg={4} xs={10} style={{ textAlign: "center" }}>
                    <img className="bride" alt="" src="/asset/media/pic/topBride.png"></img>

                </Grid>
                <Grid item lg={4} xs={11}>
                    <Box style={{ height: '200px' }}>
                        <TypingComponent strings={typedStrings} />
                    </Box>
                    {/* <Link to="/card"
                    //  state={{ guestCart: data?.exampleCartData[0] }} 
                      className="showExampleCard">همین الان کلیک کنید</Link> */}
                </Grid>
            </Grid>

            <h2 className="aroosiOnline">aroosi.online</h2>
        </section>
    );
};

export default BannerComponent;

import { Box, Typography, Grid } from '@mui/material';
// import * as data from "../../../../../data";
import { useAuth } from '../../../auth/authContext';


const TopDashboardPage = () => {
    const { cartData } = useAuth();

    return (
        <Box style={{ width: "1000px", maxWidth: "100%", margin: "0 auto", position: 'left' }}>
            <Grid container spacing={2} sx={{ width: "100%" }}>
                <Grid item xs={12}
                    sx={{
                        display: "flex", alignItems: "center", justifyContent: "space-between", color: '#ccae62',
                        padding: "30px 10px 0 !important", flexDirection: "column",
                        fontFamily: 'Parisienne', fontSize: "40px", img: { width: "80px", margin: "0 20px" }
                    }}>
                    <Typography variant="">{cartData?.bride_name_eng}</Typography>
                    <img src='/asset/media/pic/ring.jpg' />
                    <Typography variant="">{cartData?.groom_name_eng}</Typography>
                </Grid>

                <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", paddingLeft: "0 !important", flexWrap: "wrap" }}>
                    <Box sx={{
                        borderRight: '15px solid #22a6b3',
                        margin: "10px 0",
                        maxWidth: "245px", width: "100%", height: "100px",
                        backgroundColor: "#7ed6df",
                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;',
                        borderRadius: "5px", color: "#fff",
                        display: "flex", alignItems: "center", position: "relative",
                        "@media (max-width: 500px)": {
                            maxWidth: "48%",
                            display: "block",
                            paddingRight: "8px",
                        },
                    }}
                    >
                        <img alt='' src="/asset/media/icon/wedding-card.png" className='CardsData' />
                        <Box>
                            <Typography>مهمان‌ها</Typography>
                            <Typography variant="h5" sx={{ position: "absolute", left: '9px', bottom: "0" }}>{cartData?.visitedCarts}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        borderRight: '15px solid #22a6b3',
                        margin: "10px 0",
                        maxWidth: "245px", width: "100%", height: "100px",
                        backgroundColor: "#7ed6df",
                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;',
                        borderRadius: "5px", color: "#fff",
                        display: "flex", alignItems: "center", position: "relative",
                        "@media (max-width: 500px)": {
                            maxWidth: "48%",
                            display: "block",
                            paddingRight: "8px",
                        },
                    }}
                    >
                        <img alt='' src="/asset/media/icon/wedding-card.png" className='CardsData' />
                        <Box>
                            <Typography>کارت‌های بازدیدشده</Typography>
                            <Typography variant="h5" sx={{ position: "absolute", left: '9px', bottom: "0" }}>{cartData?.visitedCarts}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        borderRight: '15px solid #6ab04c',
                        margin: "10px 0",
                        maxWidth: "245px", width: "100%", height: "100px",
                        backgroundColor: "#badc58",
                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;',
                        borderRadius: "5px", color: "#fff",
                        display: "flex", alignItems: "center", position: "relative",
                        "@media (max-width: 500px)": {
                            maxWidth: "48%",
                            display: "block",
                            paddingRight: "8px",
                        },
                    }}
                    >
                        <img alt='' src="/asset/media/icon/cheers-white.png" className='CardsData' />
                        <Box>
                            <Typography>مهمان‌ها</Typography>
                            <Typography variant="h5" sx={{ position: "absolute", left: '9px', bottom: "0" }}>{cartData?.totalNumberOfGuests}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        borderRight: '15px solid #eb4d4b',
                        margin: "10px 0",
                        maxWidth: "245px", width: "100%", height: "100px",
                        backgroundColor: "#ff7979",
                        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;',
                        borderRadius: "5px", color: "#fff",
                        display: "flex", alignItems: "center", position: "relative",
                        "@media (max-width: 500px)": {
                            maxWidth: "48%",
                            display: "block",
                            paddingRight: "8px",
                        },
                    }}
                    >
                        <img alt='' src="/asset/media/icon/wedding-day.png" className='CardsData' />
                        <Box>
                            <Typography>روزهای باقی مانده</Typography>
                            <Typography variant="h5" sx={{ position: "absolute", left: '9px', bottom: "0" }}>{cartData?.daysLeft}</Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sx={{ backgroundColor: "#c1c5ff30", borderRight: "10px solid #40407a", padding: "10px", marginBottom: "20px", boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
                    <Typography>{cartData ? `${cartData?.bride_name} و ${cartData?.groom_name} عزیز` : "زوج عزیز"} خوش آمدید</Typography>
                    <Box sx={{ borderBottom: "1px solid #40407a", margin: "10px 0" }}></Box>
                    <Typography>
                        خیلی خوشحال و خرسندیم که تیم عروسی آنلاین را برای مراسم خودتان انتخاب کردید.
                        امیدواریم بتوانیم یاری بخش شما برای برگذاری مراسمی خاطره انگیز باشیم.
                    </Typography>
                    <Typography sx={{ color: "red" }}>برای استفاده هرچه بهتر و راحتی شما، پیشنهاد میکنیم از لپ تاپ یا کامپیوتر استفاده کنید.</Typography>
                </Grid>

                <Grid item xs={12} sx={{ backgroundColor: "#22709310", borderRight: "10px solid #227093", padding: "10px", boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
                    <Typography>در این پنل شما میتوانید</Typography>
                    <Typography>
                        لیست میهمانان خود را ایجاد و به مرور تکمیل نمایید تا از طریق همین سامانه و به وسیله پیامک کارت دعوت دیجیتال خود را به دستشان برسانید.
                    </Typography>
                    <Box sx={{ borderBottom: "1px solid #227093", margin: "10px 0" }}></Box>
                    <Typography>
                        محل برگذاری مراسم خود را با استفاده از راهنما، روی برنامه‌های مشخص شده پیدا کنید تا سامانه از طریق کارت مختصات آن را در اختیار میهمانانتان قرار دهد، با این امکان مطمئن می‌شوید که میهمانان  سردرگم پیدا نمودن نشانی تالار/باغ شما نمی‌شوند و می‌توانند از نزدیک‌ترین و کم ترافیک‌ترین مسیر خود را به میهمانی شما برسانند.
                    </Typography>
                </Grid>
            </Grid>

        </Box>
    );

};


export default TopDashboardPage;
// work => change state
const cartReducer = (state, action) => {
    switch (action.type) {
        case "ADD_TO_CART":
            {
                const updatedCart = [...state.cart];
                const idex = updatedCart.findIndex(item => item.id === action.payload.id);
                updatedCart.splice(0);
                if (idex < 0) {
                    updatedCart.push({ ...action.payload, quantity: 1 })
                }
                return { ...state, cart: updatedCart };
            }
        case "REMOVE_CARITEM":
            {
                const updatedCart = [...state.cart];
                const filteredCart = updatedCart.filter(item => item.id !== action.payload.id)
                return { ...state, cart: filteredCart };
            }
        default: return state;
    }
};
export default cartReducer;
import { useState, useEffect } from 'react';
import UploadImage from './UploadImage';
import axios from 'axios';
import { Box, Grid, Typography } from '@mui/material';
import { useAuth } from '../../../../../auth/authContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const ImagePage = () => {
    const { token, fetchCartData, cartData } = useAuth();
    const [uploadedImages, setUploadedImages] = useState({});

    useEffect(() => {
        // Load existing images from cartData if available
        if (cartData) {
            setUploadedImages({
                bride_avatar: cartData.bride_avatar || null,
                groom_avatar: cartData.groom_avatar || null,
                background: cartData.background || null,
            });
        }
    }, [cartData]);

    const handleImageUpload = (name, imageFile) => {
        setUploadedImages(prevState => ({
            ...prevState,
            [name]: imageFile
        }));
    };

    const handleSendImages = async () => {
        try {
            const formData = new FormData();
            // Append each uploaded image
            for (const name in uploadedImages) {
                if (uploadedImages[name]) {
                    formData.append(name, uploadedImages[name]);
                }
            }
            const response = await axios.post(`${BASE_URL}/api/licence/current`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                },
            });
            toast.success(response?.data?.message);
        } catch (error) {
            toast.error(error?.response?.data?.message);
        }
    };

    return (
        <Box className="formInformation">
            {/* <Box className="formDisable">به زودی...</Box> */}
            {cartData?.licence_id === 1 ?
                <Box className="licenseDisable">
                    <Link to="/plan/3" className="licenseLink">اپدیت پکیج</Link>
                </Box>
                : ""
            }
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">عکس</Typography>
                    <Typography variant="p">اپلود عکس اختیاری است. </Typography>
                    <Typography variant="p" sx={{color:"red"}}>حجم عکس‌ها باید کمتر از 500kb باشد.</Typography>
                    <Typography variant="inherit">کاهش حجم عکس: <a href='https://compressjpeg.com/' target='_blank'>compressjpeg.com</a></Typography>
                </Grid>

                <Grid sx={{ display: "flex", flexDirection: 'column', alignItems: "center" }} item xs={12} md={4}>
                    <Typography variant="h6">عروس</Typography>
                    <UploadImage
                        initialImageUrl={`https://aroosi.online/storage/${cartData?.bride_avatar}`}
                        onImageUpload={(imageFile) => handleImageUpload("bride_avatar", imageFile)}
                    />
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: 'column', alignItems: "center" }} item xs={12} md={4}>
                    <Typography variant="h6">داماد</Typography>
                    <UploadImage
                        initialImageUrl={`https://aroosi.online/storage/${cartData?.groom_avatar}`}
                        onImageUpload={(imageFile) => handleImageUpload("groom_avatar", imageFile)}
                    />
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: 'column', alignItems: "center" }} item xs={12} md={4}>
                    <Typography variant="h6">پس زمینه</Typography>
                    <UploadImage
                        initialImageUrl={`https://aroosi.online/storage/${cartData?.background}`}
                        onImageUpload={(imageFile) => handleImageUpload("background", imageFile)}
                    />
                </Grid>

                <Grid sx={{ display: "flex", justifyContent: "flex-end", marginTop: "15px" }} item xs={12}>
                    <button onClick={handleSendImages} className="saveInformation" type="submit">ذخیره</button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ImagePage;

import React, { useState } from "react";
import {
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../auth/authContext";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const ChooseCardPage = () => {
    const { token, fetchCartData, cartData } = useAuth();
    const [selectedValue, setSelectedValue] = useState(cartData?.template_id?.toString());

    const initialValues = {
        template: cartData?.template_id || "1",
    };

    const validationSchema = Yup.object({
        template: Yup.string(),
    });

    const handleSubmit = (values) => {
        axios
            .post(`${BASE_URL}/api/licence/current`, {
                template_id: selectedValue,
            },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                toast.success(response?.data?.message);
                fetchCartData();
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message);
            });
    };

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue }) => (
                    <Form className="formInformation">
                        <Grid container spacing={2} sx={{ width: "100% !important" }}>
                            <Grid item xs={12}>
                                <Typography variant="h5">قالب کارت</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ paddingLeft: "0px" }}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={selectedValue}
                                    onChange={handleChange}
                                    style={{
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                    }}
                                >
                                    <FormControlLabel
                                        className="labelTemplate"
                                        value="1"
                                        control={<Radio style={{ display: "none" }} />}
                                        label={<div className={selectedValue === "1" ? 'templateSelected' : 'templateSelect'}>
                                            <img src='/asset/media/pic/carts/cart1.jpg' />
                                        </div>}
                                    />
                                    <FormControlLabel
                                        disabled={cartData?.licence_id === 1}
                                        className="labelTemplate"
                                        value="2"
                                        control={<Radio style={{ display: "none" }} />}
                                        label={<div className={selectedValue === "2" ? 'templateSelected' : 'templateSelect'}>
                                            {cartData?.licence_id === 1 ?
                                                <div className="licenseDisable">
                                                    <Link to="/plan/2" className="licenseLink">اپدیت پکیج</Link>
                                                </div>
                                                : ""
                                            }
                                            <img src='/asset/media/pic/carts/cart2.jpg' />
                                        </div>}
                                    />
                                    <FormControlLabel
                                        disabled={cartData?.licence_id === 1}
                                        className="labelTemplate"
                                        value="3"
                                        control={<Radio style={{ display: "none" }} />}
                                        label={<div className={selectedValue === "3" ? 'templateSelected' : 'templateSelect'}>
                                            {cartData?.licence_id === 1 ?
                                                <div className="licenseDisable">
                                                    <Link to="/plan/3" className="licenseLink">اپدیت پکیج</Link>
                                                </div>
                                                : ""
                                            }
                                            <img src='/asset/media/pic/carts/cart3.jpg' />
                                        </div>}
                                    />
                                    <FormControlLabel
                                        disabled={cartData?.licence_id === 1}
                                        className="labelTemplate"
                                        value="4"
                                        control={<Radio style={{ display: "none" }} />}
                                        label={<div className={selectedValue === "4" ? 'templateSelected' : 'templateSelect'}>
                                            {cartData?.licence_id === 1 ?
                                                <div className="licenseDisable">
                                                    <Link to="/plan/4" className="licenseLink">اپدیت پکیج</Link>
                                                </div>
                                                : ""
                                            }
                                            <img src='/asset/media/pic/carts/cart4.jpg' />
                                        </div>}
                                    />
                                    <FormControlLabel
                                        disabled={cartData?.licence_id === 1}
                                        className="labelTemplate"
                                        value="5"
                                        control={<Radio style={{ display: "none" }} />}
                                        label={<div className={selectedValue === "5" ? 'templateSelected' : 'templateSelect'}>
                                            {cartData?.licence_id === 1 ?
                                                <div className="licenseDisable">
                                                    <Link to="/plan/3" className="licenseLink">اپدیت پکیج</Link>
                                                </div>
                                                : ""
                                            }
                                            <img src='/asset/media/pic/carts/cart5.jpg' />
                                        </div>}
                                    />
                                    <FormControlLabel
                                        className="labelTemplate"
                                        value="6"
                                        control={<Radio style={{ display: "none" }} />}
                                        label={<div className={selectedValue === "6" ? 'templateSelected' : 'templateSelect'}>

                                            <img src='/asset/media/pic/carts/cart6.jpg' />
                                        </div>}
                                    />
                                    <FormControlLabel
                                        disabled={cartData?.licence_id === 1}
                                        className="labelTemplate"
                                        value="7"
                                        control={<Radio style={{ display: "none" }} />}
                                        label={<div className={selectedValue === "7" ? 'templateSelected' : 'templateSelect'}>
                                            {cartData?.licence_id === 1 ?
                                                <div className="licenseDisable">
                                                    <Link to="/plan/3" className="licenseLink">اپدیت پکیج</Link>
                                                </div>
                                                : ""
                                            }
                                            <img src='/asset/media/pic/carts/cart7.jpg' />
                                        </div>}
                                    />
                                    <FormControlLabel
                                        disabled={cartData?.licence_id === 1}
                                        className="labelTemplate"
                                        value="8"
                                        control={<Radio style={{ display: "none" }} />}
                                        label={<div className={selectedValue === "8" ? 'templateSelected' : 'templateSelect'}>
                                            {cartData?.licence_id === 1 ?
                                                <div className="licenseDisable">
                                                    <Link to="/plan/3" className="licenseLink">اپدیت پکیج</Link>
                                                </div>
                                                : ""
                                            }
                                            <img src='/asset/media/pic/carts/cart8.jpg' />
                                        </div>}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>

                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: "15px",
                            }}
                            item
                            xs={12}
                        >
                            <button className="saveInformation" type="submit">
                                ذخیره
                            </button>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ChooseCardPage;

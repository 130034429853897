import React, { useState, useEffect } from "react";
import Input from "../../common/input";
import { useFormik } from "formik";
import * as Yup from "yup";
import './getCode.css'
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { Toast, toast } from "react-toastify";
import { useAuth } from "../../auth/authContext";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const initialValues = {
    code: "",
};

const validationSchema = Yup.object({
    code: Yup.string().required('رمز عبور را وارد کنید').max(4, "کد ورود 4 رقمی است."),
});

const SignupForm = () => {
    const navigate = useNavigate();
    const { login, isAuthenticated } = useAuth();
    const location = useLocation();
    const mobileNumber = location.state;

    const [timer, setTimer] = useState(60);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        let interval;
        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        setIsButtonDisabled(false);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isButtonDisabled]);

    const onSubmit = (values) => {
        axios.post(`${BASE_URL}/api/auth/verify`, { mobile: mobileNumber, code: values?.code })
            .then(response => {
                toast.success(response?.data?.message);
                const { code } = values;
                login(response?.data?.status, response?.data?.token);
                navigate('/', { replace: false });
            })
            .catch(error => {
                toast.error(error?.response?.data?.message);
            });
    };

    const handleResendCode = () => {
        axios.post(`${BASE_URL}/api/auth/login-request`, { mobile: mobileNumber })
            .then(response => {
                toast.success(response?.data?.message);
                login(response?.data?.status, response?.data?.token);
                setIsButtonDisabled(true);
                setTimer(60);
            })
            .catch(error => {
                toast.error(error?.response?.data?.message);
            });
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
        validateOnMount: true,
    });

    return (
        <section className="signupPage">
            <Link to="/login" className="backBtn">بازگشت</Link>

            <div className="signupPageOut">
                <img alt="" className="flowerLeft" src="/asset/media/pic/login-flower1.webp" />
                <img alt="" className="flowerRight" src="/asset/media/pic/login-flower2.webp" />

                <form className="signupPageIn" onSubmit={formik.handleSubmit}>
                    <div className="title">
                        <h1>کد ورورد</h1>
                        <img alt="" className="flower" src="/asset/media/pic/login-title.webp" />
                    </div>

                    <Input formik={formik} name="code" label="کد :" type="number" ></Input>

                    <div className="btnBox">
                        <button
                            className="resendBtn"
                            type="button"
                            onClick={handleResendCode}
                            disabled={isButtonDisabled}
                        >
                            {isButtonDisabled ? `${('0' + (timer % 60)).slice(-2)} : ${Math.floor(timer / 60)}` : 'ارسال مجدد'}
                        </button>

                        <button className="signup" type="submit" disabled={!formik.isValid}>ورود</button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default SignupForm;

// import { useState } from 'react';
// import './CustomSelector.css';
// import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

// const CustomSelector = ({ options, onSelection, placeholder }) => {
//   const [selectedOption, setSelectedOption] = useState('');

//   const handleSelectChange = (event) => {
//     const selectedValue = event.target.value;
//     setSelectedOption(selectedValue);
//     onSelection(selectedValue);
//   };

//   return (
//     <div className="custom-selector">
//       <div style={{ width: "100%" }}>
//         <Select
//           value={selectedOption}
//           onChange={handleSelectChange}
//           style={{ width: '100%' }}
//           displayEmpty
//           renderValue={(selected) => (selected ? selected : placeholder)}
//         >
//           {options?.map((option) => (
//             <MenuItem key={option.id} value={option.value} style={{ width: '100%' }}>
//               {option.value}
//             </MenuItem>
//           ))}
//         </Select>


//       </div>
//     </div>
//   );
// };

// export default CustomSelector;


import React, { useState } from 'react';
import Select from 'react-select';
import './CustomSelector.css';

const CustomSelector = ({ options, onSelection, placeholder, labelName, valueName }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onSelection(selectedOption?.value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
    }),
  };


  const mappedOptions = options?.map((option) => ({
    label: option[labelName],
    value: option[valueName],
  }));

  return (
    <div className="custom-selector">
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={mappedOptions}
        styles={customStyles}
        placeholder={placeholder}
        isClearable={true}
      />
    </div>
  );
};

export default CustomSelector;

import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Layout/Layout';
import { Box } from '@mui/material';

const BlogPage = () => {
  const blogs = [
    { id: 1, title: 'Blog 1' },
    { id: 2, title: 'Blog 2' },
    // ...
  ];

  return (
    <Layout>
      <Box mt={10}>
      <h2>Blog Page</h2>
      <ul>
        {blogs?.map(blog => (
          <li key={blog.id}>
            <Link to={`/blog/${blog.id}`}>{blog.title}</Link>
          </li>
        ))}
      </ul>
      </Box>
    </Layout>
  );
};

export default BlogPage;
